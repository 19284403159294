import {store} from './store'
import {web_register, web_login, web_categories, web_articles, web_place_order, web_orders/*, web_barorders*/, web_adopt_account, web_accept_transfer, web_set_articleavailability,web_refund_orderline,
web_set_orderstatus,ws_connect, ws_disconnect,  web_order_details,web_call_waiter, web_set_callstatus, web_get_credits, web_add_credits, web_get_order_history, web_create_transfer, web_get_payments} from './web'
import {LOGIN_OK, LOGIN_NOK, SET_ARTICLES, SET_CATEGORIES, CREDITS, ORDERS, ROLES, BARORDERS, SET_IDENTIFIER, CALLS, ADD_IDENTIFIER, OLD_ORDERS, SET_DASHBOARDDATA, SET_ONLINE, SET_SHOWWELCOME, STORE_LOCATIONS, PAYCONIQ_PAYMENT_STATUS} from './actions'

export function api_register(name){
	return new Promise((resolved,rejected)=>{
		web_register(name)
		.then(result => {
			//console.log("register result:"+JSON.stringify(result))
			if (result.result===true) {
				if (result.data ) {
					store.dispatch({ type: ADD_IDENTIFIER, identifier:result.data.identifier, name})
					store.dispatch({ type: SET_IDENTIFIER, identifier:result.data.identifier, name})
				}
			}
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}

function ws_message(msg) {
	//console.log('got msg:'+JSON.stringify(msg))
	//console.log('got msg.credits:'+msg.credits)
	if (msg.categories!==undefined){
		store.dispatch({ type: SET_CATEGORIES, categories:msg.categories})
	}
	if (msg.articles!==undefined){
		store.dispatch({ type: SET_ARTICLES, articles:msg.articles})
	}
	if (msg.barorders!==undefined){
		//console.log("got msg barorders:"+JSON.stringify(msg.barorders))
		store.dispatch({ type: BARORDERS, barorders:msg.barorders})
	}
	if (msg.orders!==undefined){
		store.dispatch({ type: ORDERS, orders:msg.orders})
	}
	if (msg.old_orders!==undefined){
		store.dispatch({ type: OLD_ORDERS, old_orders:msg.old_orders})
	}
	if (msg.calls!==undefined){
		store.dispatch({ type: CALLS, calls:msg.calls})
	}
	if (msg.credits!==undefined){
		//console.log('got credits')
		store.dispatch({ type: CREDITS, credits:msg.credits})
	}
	if (msg.dashboard!==undefined){
		store.dispatch({ type: SET_DASHBOARDDATA, dashboard:msg.dashboard})
	}
	if (msg.locations!==undefined){
		store.dispatch({ type: STORE_LOCATIONS, locations:msg.locations})
	}
	if (msg.sound!==undefined) {
		//console.log("sound play requested:"+msg.sound)
		var audio = new Audio(msg.sound+'.mp3');
		audio.play();
	}
	if (msg.payment!==undefined) {
		store.dispatch({ type: PAYCONIQ_PAYMENT_STATUS, payment_status:msg.payment})
	}
}

function ws_status(status){
	store.dispatch({ type: SET_ONLINE, online:status})
}

export function api_show_welcome(showWelcome) {
	store.dispatch({ type: SET_SHOWWELCOME, showWelcome})
}

export function api_login(identifier){
	//console.log("logging in(api_login) with "+identifier)
	return new Promise((resolved,rejected)=>{
		web_login(identifier)
		.then(result => {
			//console.log("login result:"+JSON.stringify(result))
			if (result.result===true){
				console.log("login ok")
				store.dispatch({ type: LOGIN_OK, identifier})
				
				if (result.data && result.data.orders) {
					//console.log("GOT ORDERS:"+result.data.orders)
					store.dispatch({ type: ORDERS, orders:result.data.orders})
				}
				else {
					//console.log("CLR ORDERS")
					store.dispatch({ type: ORDERS, orders:[]})
				}
				if (result.data && result.data.credits!==undefined) {
					store.dispatch({ type: CREDITS, credits:result.data.credits})
				}
				if (result.data && result.data.roles) {
					store.dispatch({ type: ROLES, roles:result.data.roles})
				}
				if (result.data && result.data.barorders) {
					//console.log("GOT BARORDERS:"+result.data.barorders)
					store.dispatch({ type: BARORDERS, barorders:result.data.barorders})
				}
				else {
					//console.log("CLR BARORDERS")
					store.dispatch({ type: BARORDERS, barorders:[]})
				}
				if (result.data && result.data.calls) {
					store.dispatch({ type: CALLS, calls:result.data.calls})
				}
				if (result.data && result.data.old_orders) {
					store.dispatch({ type: OLD_ORDERS, old_orders:result.data.old_orders})
				}
				

				ws_disconnect();
				//connect for registering
				ws_connect(identifier, ws_message, ws_status)
			}
			else {
				console.log("login failed")
				store.dispatch({ type: LOGIN_NOK  })
			}
			
			
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}


export function api_categories(){
	//console.log("getting api_categories")
	return new Promise((resolved,rejected)=>{
		web_categories()
		.then( result => {
			//console.log("api_categories result:"+JSON.stringify(result))
			if (result.result===true) {
				let categories = result.data; 
				store.dispatch({ type: SET_CATEGORIES, categories})
			} else {
				store.dispatch({ type: SET_CATEGORIES, categories:[]})
			}
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}

export function api_articles(){
	return new Promise((resolved,rejected)=>{
		web_articles()
		.then( result => {
			if (result.result===true) {
				let articles = result.data; 
				store.dispatch({ type: SET_ARTICLES, articles})
			} else {
				store.dispatch({ type: SET_ARTICLES, articles:[]})
			}
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}

export function api_place_order(identifier, articles, location, comment, paymentmethod){
	return new Promise((resolved,rejected)=>{
		web_place_order(identifier, articles, location, comment, paymentmethod)
		.then( result => {
			if (result.data && result.data.orders) {
				store.dispatch({ type: ORDERS, orders:result.data.orders})
			}
			else {
				store.dispatch({ type: ORDERS, orders:[]})
			}
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}

export function api_orders(identifier){
	return new Promise((resolved,rejected)=>{
		web_orders(identifier)
		.then( result => {
			if (result.result===true) {
				let orders = result.data; 
				store.dispatch({ type: ORDERS, orders})
			} else {
				store.dispatch({ type: ORDERS, orders:[]})
			}
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
/*
export function api_barorders(identifier){
	return new Promise((resolved,rejected)=>{
		web_barorders(identifier)
		.then( result => {
			if (result.result===true) {
				let barorders = result.data; 
				store.dispatch({ type: BARORDERS, barorders})
			} else {
				store.dispatch({ type: BARORDERS, barorders:[]})
			}
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
*/
export function api_cancel_order(identifier, order_id){
	return new Promise((resolved,rejected)=>{
		web_set_orderstatus(identifier, order_id, 1)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}

export function api_prepare_order(identifier, order_id){
	return new Promise((resolved,rejected)=>{
		web_set_orderstatus(identifier, order_id, 2)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}

export function api_unprepare_order(identifier, order_id){
	return new Promise((resolved,rejected)=>{
		web_set_orderstatus(identifier, order_id, 0)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}

export function api_pause_order(identifier, order_id){
	return new Promise((resolved,rejected)=>{
		web_set_orderstatus(identifier, order_id, 3)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_finished_order(identifier, order_id){
	return new Promise((resolved,rejected)=>{
		web_set_orderstatus(identifier, order_id, 4)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_cancelfinished_order(identifier, order_id){
	return new Promise((resolved,rejected)=>{
		web_set_orderstatus(identifier, order_id, 2)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_ship_order(identifier, order_id){
	return new Promise((resolved,rejected)=>{
		web_set_orderstatus(identifier, order_id, 5)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_delivered_order(identifier, order_id){
	return new Promise((resolved,rejected)=>{
		web_set_orderstatus(identifier, order_id, 6)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_remake_order(identifier, order_id){
	return new Promise((resolved,rejected)=>{
		web_set_orderstatus(identifier, order_id, 0)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_return_order(identifier, order_id){
	return new Promise((resolved,rejected)=>{
		web_set_orderstatus(identifier, order_id, 3)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_order_details(order_id) {
	return new Promise((resolved,rejected)=>{
		web_order_details(order_id)
		.then( result => {
			return resolved(result.data)
		})
		.catch(err => rejected(err))
	})
}
export function api_call_waiter(identifier, location, comment){
	return new Promise((resolved,rejected)=>{
		web_call_waiter(identifier, location, comment)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_handle_call(identifier, call_id){
	return new Promise((resolved,rejected)=>{
		web_set_callstatus(identifier, call_id, 1)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_cancel_call(identifier, call_id){
	return new Promise((resolved,rejected)=>{
		web_set_callstatus(identifier, call_id, 0)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_finish_call(identifier, call_id){
	return new Promise((resolved,rejected)=>{
		web_set_callstatus(identifier, call_id, 2)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_get_credits(user_id) {
	return new Promise((resolved,rejected)=>{
		web_get_credits(user_id)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_add_credits(identifier, user_id, amount, paymentmethod) {
	return new Promise((resolved,rejected)=>{
		web_add_credits(identifier, user_id, amount, paymentmethod)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_get_order_history(identifier) {
	return new Promise((resolved,rejected)=>{
		web_get_order_history(identifier)
		.then( result => {
			if (result.result===true) {
				if (result.data ) {
					store.dispatch({ type:OLD_ORDERS, old_orders:result.data.orders})
				}
			}
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_create_transfer(identifier, amount) {
	return new Promise((resolved,rejected)=>{
		web_create_transfer(identifier, amount)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_adopt_account(transfer_identifier) {
	return new Promise((resolved,rejected)=>{
		web_adopt_account(transfer_identifier)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_accept_transfer(identifier, transfer_identifier) {
	return new Promise((resolved,rejected)=>{
		web_accept_transfer(identifier, transfer_identifier)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
export function api_set_article_availability(identifier, article_id, available){
	return new Promise((resolved,rejected)=>{
		web_set_articleavailability(identifier, article_id, available)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}

export function api_refund_orderline(identifier, orderline_id, count){
	return new Promise((resolved,rejected)=>{
		web_refund_orderline(identifier, orderline_id, count)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}

export function api_get_payments(identifier) {
	return new Promise((resolved,rejected)=>{
		web_get_payments(identifier)
		.then( result => {
			return resolved(result)
		})
		.catch(err => rejected(err))
	})
}
