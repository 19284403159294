export const UPDATE_BASKET = "UPDATE_BASKET"
export const CLEAR_BASKET = "CLEAR_BASKET"
export const LOGIN_OK = "LOGIN_OK"
export const CREDITS = "CREDITS"
export const ORDERS = "ORDERS"
export const OLD_ORDERS = "OLD_ORDERS"
export const CALLS = "CALLS"
export const BARORDERS = "BARORDERS"
export const ROLES = "ROLES"
export const SET_ROLE = "SET_ROLE"
export const LOGOUT = "LOGOUT"
export const LOGIN_NOK = "LOGIN_NOK"
export const LOGIN_RETRY = "LOGIN_RETRY"
export const SET_LOCATION = "SET_LOCATION"
export const SET_CATEGORIES = "SET_CATEGORIES"
export const SET_ARTICLES = "SET_ARTICLES"
export const STORE_LOCATIONS = "STORE_LOCATIONS"
export const CLEAR_IDENTIFIER = "CLEAR_IDENTIFIER"
export const SET_IDENTIFIER = "SET_IDENTIFIER"
export const DEL_IDENTIFIER = "DEL_IDENTIFIER"
export const ADD_IDENTIFIER = "ADD_IDENTIFIER"
export const SET_MENU = "SET_MENU"
export const SET_TRANSFERCREDITS = "SET_TRANSFERCREDITS"
export const SET_SHAREACCOUNT = "SET_SHAREACCOUNT"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"
export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION"
export const SET_DASHBOARDDATA = "SET_DASHBOARDDATA"
export const SET_ONLINE = "SET_ONLINE"
export const SET_SHOWWELCOME = "SET_SHOWWELCOME"
export const PAYCONIQ_PAYMENT_STATUS = "PAYCONIQ_PAYMENT_STATUS"
export const CLEAR_PAYMENT= "CLEAR_PAYMENT"
export const STORE_PAYMENT = "STORE_PAYMENT"