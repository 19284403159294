import $ from 'jquery'
import {UPDATE_BASKET, CLEAR_BASKET, LOGIN_OK, LOGIN_NOK,LOGIN_RETRY, SET_LOCATION, STORE_LOCATIONS, SET_MENU, LOGOUT,
SET_TRANSFERCREDITS, SET_SHAREACCOUNT, REMOVE_NOTIFICATION, ADD_NOTIFICATION, HIDE_NOTIFICATION, SET_DASHBOARDDATA,
SET_CATEGORIES, SET_ARTICLES, CREDITS, ORDERS, CALLS, ROLES, SET_ROLE, BARORDERS, CLEAR_IDENTIFIER,SET_IDENTIFIER, DEL_IDENTIFIER, ADD_IDENTIFIER, OLD_ORDERS, SET_ONLINE, SET_SHOWWELCOME, PAYCONIQ_PAYMENT_STATUS, CLEAR_PAYMENT, STORE_PAYMENT} from './actions'

export const order = (state = { articles:{}, payment:undefined}, action) => {
  
  //console.log("order state action.type:"+action.type + " - "+JSON.stringify(action))
	
  switch (action.type) {
    case UPDATE_BASKET:
		let articles = state.articles
		if (action.count===0) {
			if (action.article_id in articles)
				delete articles[action.article_id]
		}
		else {
			articles[action.article_id] = action.count
		}
		return $.extend({}, state, { articles});
    
	case CLEAR_BASKET:
		return $.extend({}, state, { articles:{}});
	
	case PAYCONIQ_PAYMENT_STATUS:
		{
			let articles = state.articles
			if (action.payment_status.status==="SUCCEEDED")
			{
				articles={}
			}
			return $.extend({}, state, { payment_status:action.payment_status, articles});
		}
    case CLEAR_PAYMENT:
		console.log("clearing payment")
		let s = {...state}
		delete s.payment
		return $.extend({}, s);
	case STORE_PAYMENT:
		return $.extend({}, state, { payment:action.payment});
	
    default:
		return state
  }
}


export const user = (state = { location:"", identifiers:{}, showWelcome:true}, action) => {
	//console.log("user state action.type:"+action.type + " - "+JSON.stringify(action))
	
	let identifiers
	switch (action.type) {
		case ADD_IDENTIFIER:
			identifiers = state.identifiers || {}
			identifiers[action.name] = action.identifier
			return $.extend({}, state, { identifiers});
		case DEL_IDENTIFIER:
			identifiers = state.identifiers || {}
			let id = Object.keys(identifiers).find(key => identifiers[key] === action.identifier);
			delete identifiers[id]
			return $.extend({}, state, { identifiers});
		case SET_LOCATION:
			return $.extend({}, state, { location: action.location});
		case SET_SHOWWELCOME:
			return $.extend({}, state, { showWelcome: action.showWelcome});
		default:
			return state
	}
}

export const app = (state = { 	identifier:null, name:undefined,  locations:[], loggedIn:false, loggedInFailed:false, loggedOut:false,
								categories:[], articles:[], credits:0, orders:[], roles:[], role:'consument', barorders:[], calls:[], 
								menu:'bestellen', transfer:undefined, share:undefined, notifications:[], dashboard:{}}, 
								action) => {
	//console.log("app state action.type:"+action.type + " - "+JSON.stringify(action))
	
	
	let notifications
	let menu
	let role
			
			
	switch (action.type) {
		case STORE_LOCATIONS:
			return $.extend({}, state, { locations: action.locations});
		case SET_IDENTIFIER:
			return $.extend({}, state, { loggedIn: false, loggedInFailed:false, identifier:action.identifier, username:action.name});
		case CLEAR_IDENTIFIER:
			return $.extend({}, state, { loggedIn: false, loggedInFailed:false, identifier:null});
		case LOGOUT:
			return $.extend({}, state, { loggedIn: false, loggedInFailed:false, identifier:null, loggedOut:true});
		case LOGIN_RETRY:
			return $.extend({}, state, { loggedIn: false, loggedInFailed:false});
		case LOGIN_OK:
			return $.extend({}, state, { loggedIn: true, loggedInFailed:false, identifier:action.identifier});
		case LOGIN_NOK:
			return $.extend({}, state, { loggedIn: false, loggedInFailed:true});
		case SET_CATEGORIES:
			return $.extend({}, state, { categories: action.categories});
		case SET_ARTICLES:
			return $.extend({}, state, { articles: action.articles});
		case CREDITS:
			return $.extend({}, state, { credits: action.credits});
		case ORDERS:
			return $.extend({}, state, { orders: action.orders});
		case OLD_ORDERS:
			return $.extend({}, state, { old_orders: action.old_orders});
		case CALLS:
			return $.extend({}, state, { calls: action.calls});
		case BARORDERS:
			//console.log("SETING NEW BARORDERS:"+action.barorders)
			return $.extend({}, state, { barorders: action.barorders});
		case ROLES:
			let roles=[]
			const lstRoles = ["consument","ober","bar","monitor","admin"]
			for(var r=0 ;r<action.roles.length; r++) {
				if (action.roles.charAt(r) === '1'){
					roles.push(lstRoles[r])	
				}		
			}
			if (roles.length>0)
			{
				role = roles[0]
				if (role==='consument') {
					menu='bestellen'
				}
				if (role==='ober') {
					menu='obertasks'
				}
				if (role==='bar') {
					menu='bartasks'
				}
				if (role==='monitor') {
					menu='monitorcredits'
				}
				if (role==='admin') {
					menu='dashboard'
				}
			}
			return $.extend({}, state, { roles, menu, role});
		case SET_ROLE:
			role = action.role
			if (role==='consument') {
				menu='bestellen'
			}
			if (role==='ober') {
				menu='obertasks'
			}
			if (role==='bar') {
				menu='bartasks'
			}
			if (role==='monitor') {
				menu='monitorcredits'
			}
			if (role==='admin') {
				menu='dashboard'
			}
			return $.extend({}, state, { role, menu});
		case SET_MENU:
			return $.extend({}, state, { menu: action.menu});
		case SET_TRANSFERCREDITS:
			//console.log("setting transfer to:"+action.transfer)
			return $.extend({}, state, { transfer: action.transfer});
		case SET_SHAREACCOUNT:
			//console.log("setting share to:"+action.share)
			return $.extend({}, state, { share: action.share});
		case ADD_NOTIFICATION:
			//console.log("adding notif")
			notifications = [...state.notifications]
			notifications.push({...action.notification, show:true})
			return $.extend({}, state, { notifications});
		case REMOVE_NOTIFICATION:
			notifications = state.notifications
			notifications.splice(action.notification_idx, 1); 
			return $.extend({}, state, { notifications});
		case HIDE_NOTIFICATION:
			//console.log("adding notif")
			notifications = [...state.notifications]
			notifications[action.notification_idx].show=false
			return $.extend({}, state, { notifications});
		case SET_DASHBOARDDATA:
			return $.extend({}, state, { dashboard: action.dashboard});
		default:
			return state
	}
}
export const connection = (state = { online:false}, 
								action) => {
	//console.log("connection state action.type:"+action.type + " - "+JSON.stringify(action))
			
	switch (action.type) {
		case SET_ONLINE:
			return $.extend({}, state, { online: action.online});
		default:
			return state
	}
}