import {Component} from 'react'
import { connect } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import Article from './article'

import {make_price} from './util'

class CreditsSelector extends Component {
	
	static credits = [
		{id:1, name:"Bedrag van", price:0.1},
		{id:2, name:"Bedrag van", price:1},
		{id:3, name:"Bedrag van", price:5},
		{id:4, name:"Bedrag van", price:10},
		{id:5, name:"Bedrag van", price:20}
		]
		
	constructor(props){
		super(props)
		this.state = {counts:{"1":0,"2":0,"3":0,"4":0,"5":0}, tot_price:0}
		this.countChanged = this.countChanged.bind(this)
		this.reset = this.reset.bind(this)
		
	}
	componentDidMount() {
		const { childRef } = this.props;
		childRef(this);
	}
	componentWillUnmount() {
		const { childRef } = this.props;
		childRef(undefined);
	}
	
	static defaultProps={onChange:()=>{}}
	
	reset(){
		this.setState({counts:{"1":0,"2":0,"3":0,"4":0,"5":0}})
		this.props.onChange(0)
	}
	
	
	render(){
		
		
		//console.log("CS:min-max:"+ this.props.minimumAmount+ "/"+this.props.maximumAmount)
		//console.log("tot_price="+this.state.tot_price)
		
		let minimumAmount = make_price(parseInt(this.props.minimumAmount*100))
		let maximumAmount = make_price(parseInt(this.props.maximumAmount*100))
		let tot_price = make_price(parseInt(parseFloat(this.state.tot_price)*100))
		//console.log("CS:min-max:"+ minimumAmount+ "/"+maximumAmount)
		//console.log("tot_price="+tot_price)
							
		
		return 	<ListGroup direction="vertical" gap={1}  >
					{
						CreditsSelector.credits.map( credit =>	{
							//console.log("this.state.counts["+credit.id+"]="+this.state.counts[credit.id])
							//console.log("credit.price="+credit.price)
							let credit_price = make_price(parseInt(credit.price*100))
							
							let minimum = undefined
							if (this.props.minimumAmount!==undefined){
								minimum = -make_price(((minimumAmount+tot_price+(-make_price(this.state.counts[credit.id]*credit_price)))/credit_price))
								//console.log("minimum:"+minimum)
								minimum = parseInt(Math.ceil(minimum))
								//console.log("minimum:"+minimum)
							}
							
							let maximum = undefined
							if (this.props.maximumAmount!==undefined){
								maximum = make_price(((maximumAmount-tot_price+(make_price(this.state.counts[credit.id]*credit_price)))/credit_price))
								//console.log("maximum:"+maximum)
								maximum = parseInt(maximum)
								//console.log("maximum:"+maximum)
							}
		
							return (
								<ListGroup.Item style={{width:'100%'}} key={credit.id} className="px-0 py-1 m-0 border-start-0 border-end-0 bg-transparent">
									<Article key={credit.id} name={credit.name} price={credit.price} minimum={minimum} maximum={maximum} count={this.state.counts[credit.id]} onChange={(cnt)=>this.countChanged(credit.id,cnt)}/>
								</ListGroup.Item>
							)
						})
					}
				</ListGroup>
	}
	
	countChanged(id, cnt){
		let counts = {...this.state.counts}
		counts[id] = cnt
		
		let tot_price = 0
		for ( let article_id in counts)
		{
		
			let count = counts[article_id]
			let id = parseFloat(article_id)
			let credit = CreditsSelector.credits.find(credit => credit.id === id)
			if (credit !== undefined)
			{
				//let credit_price = parseFloat(credit.price)
				let credit_price = make_price(parseInt(credit.price*100))
				tot_price = tot_price + count*credit_price
			}
		}
		
		
		this.setState({counts, tot_price:make_price(tot_price/100)})
		
		this.props.onChange(tot_price/100)
	}
}


function mapStateToProps(state) {
	return {identifier:state.app.identifier}
}
  
export default connect(mapStateToProps)(CreditsSelector);  
