import {Component} from 'react'
import { connect } from 'react-redux'

import ListGroup from 'react-bootstrap/ListGroup'
//import Tabs from 'react-bootstrap/Tabs'
//import Tab from 'react-bootstrap/Tab'
import Carousel from 'react-bootstrap/Carousel'
import Stack from 'react-bootstrap/Stack'

import {UPDATE_BASKET} from './actions'
import Article from './article'


class MenuKaart extends Component {
	
	constructor(props){
		super(props)
		this.state={catidx:0}
	}
	
	render_article(cat){
		return 	<ListGroup style={this.props.style}>
					{
						this.props.articles.filter(article => article.category_id === cat.category_id)
						.map( article => 
						<ListGroup.Item className="px-0 py-1 border-start-0 border-end-0 bg-transparent" key={article.article_id} style={this.props.style} >
							<Article name={article.name} info={article.info} price={article.price} 
								count={this.props.orderArticles[article.article_id]} 
								onChange={(count)=>{this.props.updateArticleOrder(article.article_id, count)}} 
								outOfStock={article.stock!==true} 
								maximum={article.stock!==true?0:undefined}
								type={this.props.type}
								onStockChange={(available)=>this.props.onStockChange(article.article_id, available)}
								/>
						</ListGroup.Item>)
					}
				</ListGroup>
	}
	render() {
		//console.log("cats:"+JSON.stringify(this.props.categories))
		//console.log("this.props.permanent:"+JSON.stringify(this.props.permanent))
		//console.log("articles:"+JSON.stringify(this.props.articles))
		let cats =  this.props.categories.filter(cat=>(cat.permanent===this.props.permanent || (cat.permanent===null && this.props.permanent===undefined)))
		//console.log("cats.length:"+cats.length)
		if (cats.length===0) {
			return <></>
		}
		else if (cats.length===1) {
			return this.render_article(cats[0])
		}
		return (
			<Stack direction="vertical" gap={1} className="p-1"  style={{borderRadius:'5px'}}>
				<Carousel id="cats" className="py-0" interval={null} indicators={false} variant="dark" onSelect={(catidx)=>this.setState({catidx})} activeIndex={this.state.catidx}>
				{
					this.props.categories.filter(cat=>cat.permanent!=='1').map( cat => 
						<Carousel.Item key={cat.category_id} >
							<div style={{textAlign:'center'}}><h3>{cat.name}</h3></div>
						</Carousel.Item>
					)
				}
				</Carousel>
				<Carousel id="articles" className="pb-5 px-0" interval={null} controls={false} variant="dark" onSelect={(catidx)=>this.setState({catidx})} activeIndex={this.state.catidx}>
				{
					this.props.categories.filter(cat=>cat.permanent!=='1').map( cat => 
						<Carousel.Item key={cat.category_id} className="bg-transparent">
							<div style={{direction:'rtl', overflowY:'auto'}}><div style={{direction:'ltr'}}>{this.render_article(cat)}</div></div>
						</Carousel.Item>
					)
				}
				</Carousel>
			</Stack>
		)
				
	}
}

function mapDispatchToProps(dispatch) {
	return {
		updateArticleOrder: (article_id, count) => {dispatch({ type: UPDATE_BASKET, article_id, count  })}		
	}
}

function mapStateToProps(state) {
	return {orderArticles: {...state.order.articles}, categories:state.app.categories, articles:state.app.articles}
	
}
  
export default connect(mapStateToProps,mapDispatchToProps)(MenuKaart);  
