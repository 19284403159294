export const local = false
const secure = local?'':'s'
const localserver = 'localhost'
const remoteserver = 'ttc-ober.onrender.com'
const server = local?localserver:remoteserver
const PORT =local? (":"+ (process.env.PORT || 5000)):""

const url ="http"+secure+"://"+server + PORT
const wsUrl = 'ws'+secure+'://'+server + PORT + '/push';

export function get_server_url(){
	return url
}

let ws = undefined
let active_identifier = undefined

export function ws_disconnect(){
	if (ws !== undefined || active_identifier !== undefined)
	{
		console.log("closing websocket");
		active_identifier = undefined
		ws.close(3000,"on command");
	}
	else
	{
		console.log("Already closed or not opened");
	}
}	

export function ws_connect(identifier, msg_cb, status_cb){

	//console.log('connecting '+identifier)
	let gotpong
	let pongcheck
	let online = false
	if (identifier === undefined)
		return
	if (ws === undefined) {
		try {
			active_identifier = identifier
		
			ws = new WebSocket(wsUrl)
		} catch (err) {
			console.log(err);
			ws=undefined;
			setTimeout( ws_connect, 5000, active_identifier, msg_cb, status_cb) 
			return
		}
	}
	
	ws.onopen = (evt => {	
		//console.log('connected:'+JSON.stringify(evt))
		if (!online){
			online=!online
			status_cb(online)
		}
		//console.log("registering "+active_identifier);
		ws.send(JSON.stringify({register:{identifier:active_identifier}})); 
		ws.send('ping');
		gotpong=false;
		})
	
	ws.onclose = (evt => { 
		console.log('closed:'+JSON.stringify(evt)); 
		if (online){
			online=!online
			status_cb(online)
		}
		ws=undefined; 
		setTimeout( ws_connect, 1000, active_identifier, msg_cb, status_cb) 
	})
	
	if (ws===undefined) return
	
	ws.onmessage = (evt => {
		try {
			//console.log("msg received:"+evt.data)
			if (evt.data==='pong'){
				//console.log('pong')
				gotpong=true
				if (pongcheck)
					clearTimeout(pongcheck)
				if (!online){
					online=!online
					status_cb(online)
				}
				setTimeout( ()=>{
					try{
						if (ws!==undefined)
							ws.send('ping');
						//console.log('ping');
						gotpong=false
					}
					catch(e) {
						console.log(e);
					}
				}, 5000)
				
				pongcheck = setTimeout( ()=>{
					if (!gotpong) {
						console.log("no pong")
						if (online){
							online=!online
							status_cb(online)
						}
						try{
							if (ws!==undefined)
								ws.send('ping');
							//console.log('ping');
							gotpong=false
						}
						catch(e) {
							console.log(e);
						}
					}
				},7000)
					
			} else {
				let o = JSON.parse(evt.data)
				msg_cb(o)
			}
		} catch (e) {
			console.log(e)
		}
	})
	
	if (ws===undefined) return
	
	ws.onerror = ( evt => {
		console.log("ws-error:"+JSON.stringify(evt));
		status_cb(false);
	})
	
}


export function web_register(name){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/register',
				{
				method:"POST", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}, 
				body:JSON.stringify({name})
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("web_register response:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}

export function web_login(identifier){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/login',
				{
				method:"POST", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}, 
				body:JSON.stringify({identifier})
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}

export function web_categories(){
	//console.log("getting categories")
	return new Promise((resolved,rejected)=>{
		fetch(url+'/categories',
				{
				method:"GET", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}
				})
		.then(res => {
			//console.log("res:"+JSON.stringify(res))
			return res.json()
			})
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	
	})
}

export function web_articles(){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/articles',
				{
				method:"GET", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}

export function web_place_order(identifier, articles, location, comment, paymentmethod){
	return new Promise((resolved,rejected)=>{
		fetch(url+'/order',
				{
				method:"POST", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}, 
				body:JSON.stringify({identifier, articles, location, comment, paymentmethod})
				})
		.then(res => res.json())
		.then((resp) => {
			console.log("web_place_order resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	
	})
}


export function web_orders(identifier){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/orders?identifier='+identifier,
				{
				method:"GET", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}

export function web_get_order_history(identifier){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/orders?identifier='+identifier+'&all=1',
				{
				method:"GET", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("web_get_order_history:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
/*
export function web_barorders(identifier){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/barorders?identifier='+identifier,
				{
				method:"GET", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}*/

export function web_set_orderstatus(identifier, order_id, status){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/order',
				{
				method:"PUT", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'},
				body:JSON.stringify({identifier, order_id, status})
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
export function web_order_details(order_id){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/order?order_id='+order_id,
				{
				method:"GET", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
export function web_call_waiter(identifier, location, comment){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/waiter',
				{
				method:"PUT", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'},
				body:JSON.stringify({identifier, location,comment})
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
export function web_set_callstatus(identifier, call_id, status){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/call',
				{
				method:"PUT", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'},
				body:JSON.stringify({identifier, call_id, status})
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}

export function web_get_credits(user_id){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/credits?user_id='+user_id,
				{
				method:"GET", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
export function web_add_credits(identifier, user_id, amount, paymentmethod){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/credits',
				{
				method:"POST", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'},
				body:JSON.stringify({identifier, user_id, amount, paymentmethod})
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
export function web_create_transfer(identifier, amount){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/transfer',
				{
				method:"POST", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'},
				body:JSON.stringify({identifier, amount})
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
export function web_adopt_account(transfer_identifier){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/transfer',
				{
				method:"PUT", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'},
				body:JSON.stringify({transfer_identifier})
				})
		.then(res => res.json())
		.then((resp) => {
			console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
export function web_accept_transfer(identifier, transfer_identifier){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/transfer',
				{
				method:"PUT", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'},
				body:JSON.stringify({identifier, transfer_identifier})
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
export function web_set_articleavailability( identifier, article_id, available){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/stock',
				{
				method:"PUT", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'},
				body:JSON.stringify({identifier, article_id, available})
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
export function web_refund_orderline(identifier, orderline_id, count){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/orderline',
				{
				method:"PUT", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'},
				body:JSON.stringify({identifier, orderline_id, count})
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}
export function web_get_payments(identifier){
	
	return new Promise((resolved,rejected)=>{
		fetch(url+'/payments?identifier='+identifier,
				{
				method:"GET", 
				cache:"no-cache", 
				headers: {'Content-Type': 'application/json'}
				})
		.then(res => res.json())
		.then((resp) => {
			//console.log("resp:"+JSON.stringify(resp))
			if (resp.error)
				return rejected(resp.error)
			return resolved(resp.response)
		})
		.catch((err)=> rejected(err))
	})
}