import {Component} from 'react'
import { connect } from 'react-redux'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { api_handle_call, api_finish_call, api_cancel_call} from './api'
import Credits from './credits'
import {XLg, CheckCircle, CurrencyEuro} from 'react-bootstrap-icons'

class Call extends Component {
	
	
	static statusses = ["Onbehandeld","Bezig","Klaar"]
	
	static defaultProps = {onChange: (cnt )=>{}, count:0}
	
	constructor(props)
	{
		super(props)
		this.handleCall = this.handleCall.bind(this)
		this.finishCall = this.finishCall.bind(this)
		this.showCallDetails = this.showCallDetails.bind(this)
		this.state={showCall:false, details:[], msg:""}
		this.mounted = false
	}
	
	componentDidMount() {
		this.mounted = true
	}
	componentWillUnmount() {
		this.mounted = false
	}
	
	render(){
		//console.log("orderdetail:"+JSON.stringify(this.state.details))
		return 	<>
					<Stack direction="horizontal" gap={1}>
						{this.props.status===0 && <Button style={{width:'100%',textAlign:'center'}} variant="success" onClick={()=>this.showCallDetails(true)}>Neem opdracht</Button>}
						{this.props.status===1 && this.props.identifier!==this.props.ober_identifier && <div style={{width:'100%',textAlign:'center'}}>Ober: {this.props.ober_name}</div>}
						{
							this.props.status===1 && this.props.identifier===this.props.ober_identifier && 
							<Stack direction="horizontal" gap={2} style={{width:'100%'}}>
								<div style={{textAlign:'left'}}><Button  variant="danger" className="p-0" onClick={()=>this.cancelCall(this.props.call_id)}><XLg size='35'/></Button></div>
								<div style={{textAlign:'center', width:'100%'}}><Button variant="primary" className="p-0" onClick={()=>this.showCallDetails(false)}><CurrencyEuro size='35'/></Button></div>
								<div style={{textAlign:'right'}}><Button variant="success" className="p-0" onClick={()=>this.finishCall(this.props.call_id)}><	CheckCircle size='35'/></Button></div>
								{/*<div><Button style={{width:'100%',textAlign:'center'}} variant="success" onClick={()=>this.showCallDetails(false)}>Help klant</Button></div>*/}
							
							</Stack>
						}
					</Stack>
					<Modal show={this.state.showCall} onHide={()=>this.setState({showCall:false})} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
						<Stack direction="vertical" gap={1}>
							<Modal.Title>Oproep van {this.props.data.user_name}@{this.props.location}</Modal.Title>
							{this.props.comment && this.props.comment.length>0 && <div style={{width:'100%', textAlign:'left'}}>Opm.: {this.props.comment}</div>}
						</Stack>	
						</Modal.Header>
						{ this.props.status===1 && <Modal.Body>
							
								<Stack direction="vertical" gap={1}>
									<div><Credits user_id={this.props.user_id}/></div>
								</Stack>
							
							
							
						</Modal.Body>
						}
						<Modal.Footer>
						<Stack direction="vertical" gap={1}>
							<Stack direction="horizontal" gap={1}>
								{/*<div><Button disabled={this.props.status!==0} onClick={()=>this.handleCall(this.props.call_id)}>Behandel</Button></div>*/}
									{/*<div><Button disabled={this.props.status!==1} variant="success" onClick={()=>this.finishCall(this.props.call_id)}>Klaar</Button></div>*/}
								<div style={{width:'100%', textAlign:'right'}}>Status: {Call.statusses[this.props.status]}</div>
							</Stack>
							{this.state.msg.length>0 && <div style={{width:'100%', textAlign:'left'}}>{this.state.msg}</div>}
							
						</Stack>
						</Modal.Footer>
					</Modal>
					
					
				</>
	}
	
	showCallDetails(help) {
		if (help){
			this.handleCall(this.props.call_id)
		} else {
			this.setState({showCall:true, msg:""})
		
		}
	}
	
	handleCall(call_id) {
		api_handle_call(this.props.identifier, call_id)
		.then( result => {
			if (result.result===false){
				if (this.mounted)
					this.setState({msg:result.error.msg})
			}
		})
		.catch (err => console.log(err))
	}
	
	cancelCall(call_id) {
		api_cancel_call(this.props.identifier, call_id)
		.then( result => {
			if (result.result===false){
				if (this.mounted)
					this.setState({msg:result.error.msg})
			}
		})
		.catch (err => console.log(err))
	}
	
	finishCall(call_id) {
		api_finish_call(this.props.identifier, call_id)
		.then( result => {
			if (this.mounted)
				if (result.result===false){
					this.setState({msg:result.error.msg})
				} else {
					this.setState({showCall:false, msg:""})
				}
		})
		.catch (err => console.log(err))
	}
	
	
	
}


function mapStateToProps(state) {
	return {identifier:state.app.identifier}
}
  
export default connect(mapStateToProps)(Call);  
