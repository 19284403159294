import {Component} from 'react'
import { connect } from 'react-redux'
//import { v4 as uuidv4 } from 'uuid';
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import Toast from 'react-bootstrap/Toast'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'

import {api_register, api_adopt_account} from './api'
import {SET_IDENTIFIER, ADD_IDENTIFIER, SET_SHAREACCOUNT} from './actions'

class Register extends Component {
	
	constructor(props){
		super(props)
		this.DoRegister = this.DoRegister.bind(this)
		this.state={show_msg:false, msg:"", name:""}
		//this.mounted = false
	}
	
	//componentWillUnmount() {
	//	this.mounted = false
	//}
	
	componentDidMount() {
		//this.mounted = true
		let identifiers = Object.values(this.props.identifiers)
		if (this.props.share || this.props.loggedOut){
			console.log("share:"+this.props.share)
			//show it in any case
		} else {
			if (identifiers.length === 0){
				//return this.render_register()
			} else {
				let names = Object.keys(this.props.identifiers) 
				if (names.includes(this.props.username)) {
					this.props.setIdentidier(this.props.identifiers[this.props.username], this.props.username)
					//return <>...</>
				}
				else{
					if (identifiers.length === 1 && !(this.props.username && this.props.username.length>0)) {
						this.props.setIdentidier(identifiers[0], names[0])
						//return <>...</>
					}
					else {
						//return this.render_register()
					}
					//return <>{'Oeps...:'+ JSON.stringify(identifiers)+' - '+identifiers.length}</>
				}
			}
		}
	}
	
	render(){
		return this.render_register()
	}
		
	
	render_register(){
		let aanmelden = 'Aanmelden'
		let showNameInput = true
		if (this.props.username && this.props.username.length>0) {
			aanmelden = aanmelden +' als '+this.props.username
			showNameInput = false
		}
		//console.log("identifiers for register:"+this.props.identifiers.length)
		return	<Container fluid className="mx-auto">
				<Stack gap={0} className="p-1 mx-auto" style={{alignText:'center', borderRadius:'5px'}}>
					<div className="ms-center text-center mx-auto" style={{alignText:'center', borderRadius:'5px', maxWidth:'500px'}}>
						<h1 style={{color:'yellow'}}>Welkom op het eetfestijn van TTC De Pinte.</h1> 
						Via deze weg kan je drank bestellen. Meld je hiervoor hieronder aan.
						<br/> 
						<br/> 
						<div className="ms-center text-center AppContent" style={{alignText:'center', borderRadius:'5px', fontSize:'10px'}}>
						TTC De Pinte gebruikt je persoonsgegevens zodat je tijdens activiteiten 
						een bestelling kan plaatsen. Verder gebeurt er niets met jouw gegevens 
						en zij worden niet opgeslagen. Door je bestelling te plaatsen ben je 
						akkoord met de verwerking van jouw gegevens en ontsla je TTC 
						De Pinte van elke aansprakelijkheid daaromtrent. Je kan jouw goedkeuring 
						weigeren door je niet te registreren en geen bestelling te plaatsen. Meer informatie kan je bekomen 
						bij elke ober of bij de zaalverantwoordelijke.
						
						</div>
						<br/>
					</div>
					<Stack gap={2} className="mx-auto p-1 AppContent" style={{ borderRadius:'5px', maxWidth:'350px', width:'100%'}}>
						{	(this.props.share || Object.keys(this.props.identifiers).length>0) &&
							<>
							<Stack gap={1} className="p-0" style={{width:'100%'}}>
								{this.props.share && <Button style={{width:'100%'}} onClick={()=>this.AdoptAccount(this.props.share)}>Doorgaan met gedeeld account</Button>}
								{Object.keys(this.props.identifiers).map(name =><Button style={{width:'100%'}} key={name} onClick={()=>{this.props.setShareAccount(null);this.props.setIdentidier(this.props.identifiers[name], name)}}>{'Doorgaan als '+name}</Button>)}
							</Stack>
							<div>
								<Container className="py-0 text-center" >Of maak een nieuw account</Container>
								<hr className="py-0 my-0"/>
							</div>
							</>
						}
						{	showNameInput && 
							<Form.Control as="input" type="text" value={this.state.name} onChange={(e)=>this.setState({name:e.target.value})} placeholder="Naam"/>
						}
						
						<Button onClick={()=>this.DoRegister()}>{aanmelden}</Button>
					</Stack>
					<Toast 
						className="ms-auto" 
						delay={3000}  autohide
						onClose={() => { this.setState({show_msg:false})}} show={this.state.show_msg}>
						<Toast.Header>
							<strong className="me-auto">"Problem..."</strong>
						</Toast.Header>
						<Toast.Body>{this.state.msg}</Toast.Body>
					</Toast>
				</Stack>
		</Container>
	}
	
	AdoptAccount(share){
		console.log("about to adopt account:"+share)
		api_adopt_account(share)
		.then( response => {
			if (response.result===false) {
				this.setState({show_msg:true, msg:response.error.msg.toString()})
			} else {
				this.props.setShareAccount(null)
				this.props.addIdentidier(response.data.identifier, response.data.name)
				this.props.setIdentidier(response.data.identifier, response.data.name)
			}
			
		})
		.catch (err => {
			console.log("Could not use shared account")
			console.log(err)
			this.setState({show_msg:true, msg:err.toString()})
			
		})
	}
	
	DoRegister() {
		console.log("this.props.username:"+this.props.username)
		let name = this.props.username
		if (this.props.username===undefined)
		{
			console.log("this.state.name.trim():"+this.state.name.trim())
		
			name = this.state.name.trim()
			if (name === undefined || name.length===0) {
				this.setState({show_msg:true, msg:"Gelieve een geldige naam in te geven"})
				return
			}
			//name = uuidv4()
		}
			
		api_register(name)
		.then(result => { 
			if (result.error)
			{
				this.setState({show_msg:true, msg:result.error})
			} else if (result.data)	{
				if (result.data.error){
					this.setState({show_msg:true, msg:result.data.error.msg})
				} else {
					//registration ok!
					this.props.setShareAccount(null)
				}
			}
		})
		.catch (err => {
			console.log("something terrible went wrong")
			console.log(err)
			this.setState({show_msg:true, msg:err.toString()})
			
		})
			
		
	}
}
function mapDispatchToProps(dispatch) {
	return {
		setIdentidier: (identifier, name) => dispatch({ type:SET_IDENTIFIER, identifier, name}),
		addIdentidier: (identifier, name) => dispatch({ type:ADD_IDENTIFIER, identifier, name}),
		setShareAccount: (share) => dispatch({type:SET_SHAREACCOUNT, share})
		/*register: () => {console.log("clear");dispatch({ type: CLEAR_IDENTIFIER  })},
		login_failed: () => {console.log("clear");dispatch({ type: LOGIN_NOK  })},
		login_retry: () => {console.log("clear");dispatch({ type: LOGIN_RETRY  })},*/
		
	}
}
function mapStateToProps(state) {
	return {
		identifiers:(state.user.identifiers || {}),
		share: state.app.share,
		transfer: state.app.transfer,
		loggedOut:state.app.loggedOut,
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Register); 