import './App.css';
import {Component} from 'react'
import { connect } from 'react-redux'
import Stack from 'react-bootstrap/Stack'
import Container from 'react-bootstrap/Container'

import Menu from './menu'
import Notifications from './notifications'

import {api_categories, api_articles} from './api'
//import {local, get_server_url} from './web'


class Admin extends Component {

	
	constructor(props) {
		super(props)
		this.state={windowHeight:0}
		this.handleResize = this.handleResize.bind(this)
		this.mounted = false
		
	}
	
	
	componentDidMount(){
		this.mounted = true
		window.addEventListener('resize', ()=>{ this.handleResize()})
		this.handleResize()
		api_categories()
		.then( () => api_articles())
	}
	handleResize(){
		const {  innerHeight: height } = window
		if (this.mounted)
			this.setState({windowHeight:height})
	}
	componentWillUnmount(){
		this.mounted = false
		window.removeEventListener('resize', this.handleResize)
	}
	
	format_price(price)
	{
		price = price.toString()
		if (price.includes('.')){
			price = price +'00'
			let pos = price.indexOf('.')
			price = price.substring(0,pos+3)
			price = price.replace('.', ',')
		}
		return price
	}
	
	render(){
		return 	<Container id="ctrlcntr" style={{height:'100%'}} fluid className="px-0 py-0" >
					<Stack id="stackall" direction="vertical" gap={1} className="mx-auto px-0 py-0 flex-column" style={{maxWidth:'600px', height:'100%'}}>
						<Menu />
						<Stack className="flex-column py-0" direction="vertical" gap={1} style={{borderRadius:'5px'}}>
							<div className="AppContent flex-grow-1" >
								{this.props.menu==='dashboard' && this.render_dashboard()}
							</div>
						</Stack>
						
					</Stack>
				</Container>
		
	}

	render_dashboard() {	
		return 	<Stack className="flex-column p-0 m-0" direction="vertical" gap={0} style={{borderRadius:'5px', height:this.state.windowHeight-8-121, overflow:'auto'}}>
					<div><Notifications/></div>
				</Stack>	
	}
	/*render_orderhistory() {	
		return 		<div style={{height:this.state.windowHeight-8-84, overflow:'auto'}}><OrderHistory orderType="consument" ></OrderHistory></div>
					
				
	}*/
	
}

function mapStateToProps(state) {
	return {identifier:state.app.identifier, menu:state.app.menu }
}

export default connect(mapStateToProps)(Admin); 