import {Component} from 'react'
import { connect } from 'react-redux'

import Stack from 'react-bootstrap/Stack'
import Toast from 'react-bootstrap/Toast'

import {REMOVE_NOTIFICATION, HIDE_NOTIFICATION} from './actions'




class Notifications extends Component {

	/*shouldComponentUpdate(nextProps, nextState){
		console.log("props:"+JSON.stringify(this.props))
		console.log("nextProps:"+JSON.stringify(nextProps))
	}*/

	render(){
		let msgToShow = this.props.messages.filter(msg=>msg.show)
		
		if (msgToShow.length===0) return <></>
		return 	<Stack direction="vertical" gap={1} className="p-1" style={{borderRadius:'5px'}}>
					{
						this.props.messages.filter(msg=>msg.show).map( msg =>
							<Toast key={this.props.messages.indexOf(msg)} delay={10000}  autohide show={msg.show} style={{width:'100%'}}
								onClose={() => { this.props.hideMessage(this.props.messages.indexOf(msg)); setTimeout(()=>this.props.removeMessage(this.props.messages.indexOf(msg)),3000)}}	>
								<Toast.Header>
									<strong className="me-auto">{msg.title}</strong>
								</Toast.Header>
								<Toast.Body>{msg.message}</Toast.Body>
							</Toast>)
					}
					
				</Stack>
	}
}


function mapDispatchToProps(dispatch) {
	return {
		removeMessage: (notification_idx) => {dispatch({ type: REMOVE_NOTIFICATION, notification_idx  })},
		hideMessage: (notification_idx) => {dispatch({ type: HIDE_NOTIFICATION, notification_idx  })}
	}
}

function mapStateToProps(state) {
	//console.log("new state.app.notifications:"+JSON.stringify(state.app.notifications))
	return {messages:state.app.notifications}
	
}
  
export default connect(mapStateToProps,mapDispatchToProps)(Notifications); 
