import {Component} from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Image from 'react-bootstrap/Image'

export default class ScreenSaver extends Component {
	
	static sponsors=[
		"https://ttcdepinte.be/sponsors_imgs/arte.jpg",
		"https://ttcdepinte.be/sponsors_imgs/TKE.jpg",
		"https://ttcdepinte.be/sponsors_imgs/delhaize.jpg",
		"https://ttcdepinte.be/sponsors_imgs/reynaert.png"
		]
	
	render(){
		let list = [...ScreenSaver.sponsors]
		
		return 		<Carousel controls={false} indicators={false} interval={5000} pause={false} fade={true} style={{width:'100%'}}>
						{ 	
							[...Array(ScreenSaver.sponsors.length).keys()].map( l => {
									let idx = Math.floor(Math.random()*list.length)
									let url = list[idx]
									list.splice(idx,1)
									return (<Carousel.Item key={url}><Image src={url} style={{width:'100%', height:'100%'}}/></Carousel.Item>)
							})
						}
						
					</Carousel>
	}
	
	
}