import {Component} from 'react'
import { connect } from 'react-redux'

import ListGroup from 'react-bootstrap/ListGroup'
import Order from './order'

import {api_get_order_history} from './api'

class Orders extends Component {
	
	constructor(props){
		super(props)
		this.state = {fetching:false}
		//this.fetching = false
		this.mounted = false
	}
	
	componentWillUnmount(){
		this.mounted = false
	}
	
	componentDidMount(){
		this.mounted = true
		this.setState({fetching:true})
		//console.log("getting history")
		api_get_order_history(this.props.identifier)
		.then(result => {
			//console.log("got history:"+JSON.stringify(result))
			if (this.mounted)
				this.setState({fetching:false})
			/*if (this.mounted){
				if (result.result){
					this.setState({orders:result.data.orders})
				}
			}*/
			
		})
		.catch(err=>{
			console.log(err)
			if (this.mounted)
				this.setState({fetching:false})
		})

	}

	render_list(){
		return 	<ListGroup className="px-1 py-1 mx-0 my-0" style={{borderRadius:'5px'}}>
					{
						this.props.orders.map( order =>
							<ListGroup.Item className="px-0 py-0 mx-0 my-0 bg-transparent" key={order.order_id}>
								<Order order_id={order.order_id} order_number={order.order_number} order_amount={order.order_amount} status={order.order_status} orderType={this.props.orderType} order={order}/>
							</ListGroup.Item>)
					}
				</ListGroup>
	}
	render(){
		if (this.state.fetching)
			return <>Bezig met ophalen...</>
		
		if (this.props.orders && this.props.orders.length>0)
			return this.render_list()
		
		
		return <>Geen voorgaande bestellingen</>
	}

}


function mapDispatchToProps(dispatch) {
	return {
		/*clearBasket: () => {dispatch({ type: CLEAR_BASKET  })}*/
		
	}
}

function mapStateToProps(state) {
	return {identifier:state.app.identifier, orders:state.app.old_orders}
	
}
  
export default connect(mapStateToProps,mapDispatchToProps)(Orders); 