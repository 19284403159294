import React, {Component} from 'react'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'


import {SET_LOCATION} from './actions'


class LocationSelection extends Component {
	
	
	constructor(props) {
		super(props)
		this.state={positionedLocations:[], show:this.props.show}
		this.SelectLocation = this.SelectLocation.bind(this)
	}
	componentDidMount() {
		const { childRef } = this.props;
		childRef(this);
	}
	componentWillUnmount() {
		const { childRef } = this.props;
		childRef(undefined);
	}
	
	render() {
		//console.log("locations:"+JSON.stringify(this.props.locations))
		return (
			
			<Modal show={this.state.selectLocation} onHide={()=>this.setState({selectLocation:false})} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
						<Modal.Header closeButton>
							<Modal.Title>Selecteer de locatie</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Table borderless size="sm" className="p-0 m-0">
								<tbody className="p-0 m-0">
								{
									this.state.positionedLocations.map( (locRow,r) => {
										return (
												<tr key={r} className="p-0 m-0" style={{height:'1em'}}>
													{
														locRow.map((loc,c)=> {
															if (loc.name==null) return <td key={c} className="p-0 m-0"/>
															return (<td key={c} className="p-1 m-0">
																		<Button className="p-1 m-0" style={{width:'100%'}} onClick={()=>{
																				this.setState({selectLocation:false}); 
																				if (this.props.onLocationSelected)
																				{
																					this.props.onLocationSelected(loc.name)
																				}
																				else
																				{
																					this.props.setLocation(loc.name);
																				}	
																			}}>
																			{loc.name}
																		</Button>
																	</td>)
														})
													}
												</tr>
												)
									})
								}
								</tbody>
							</Table>
						</Modal.Body>
						
					</Modal>
			
	
		)
	}
	
	SelectLocation(){
		let locs=[]
		let minRow=1000
		let maxRow=-1000
		let minCol=1000
		let maxCol=-1000
		this.props.locations.forEach(loc => {
			
			if (loc.col==null || loc.row==null) return
			
			if (loc.col<minCol)
				minCol = loc.col
			if (loc.col>maxCol)
				maxCol = loc.col
			if (loc.row<minRow)
				minRow = loc.row
			if (loc.row>maxRow)
				maxRow = loc.row
		})
		for(var r = minRow; r<=maxRow ; r++)
		{
			let locCols=[]
			for(var c = minCol; c<=maxCol ; c++)
			{
				locCols.push({})
			}
			locs.push(locCols)
		}
		this.props.locations.forEach(loc => {
			if (loc.col==null || loc.row==null) return
			
			locs[loc.row-minRow][loc.col-minCol]=loc
		})
		
		//console.log("positionedLocations:"+JSON.stringify(locs))
		this.setState({selectLocation:true, positionedLocations:locs})
	}
	
	
}
function mapDispatchToProps(dispatch) {
	return {
		setLocation: (location) => dispatch({type:SET_LOCATION, location})
	}
}

function mapStateToProps(state) {
	return {
		locations: state.app.locations
		}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(LocationSelection);  





