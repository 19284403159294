import './App.css';
import {Component} from 'react'
import { connect } from 'react-redux'

import Stack from 'react-bootstrap/Stack'
//import Tab from 'react-bootstrap/Tab'
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
import Carousel from 'react-bootstrap/Carousel'
import Card from 'react-bootstrap/Card'

import Menu from './menu'
import Order from './order'
import Call from './call'

class Ober extends Component {
	
	constructor(props) {
		super(props)
		this.state={windowHeight:0}
		this.handleResize = this.handleResize.bind(this)
		this.mounted = false
	}
	componentDidMount(){
		this.mounted = true
		window.addEventListener('resize', ()=>{ this.handleResize()})
		this.handleResize()
	}
	handleResize(){
		const {  innerHeight: height } = window
		if (this.mounted)
			this.setState({windowHeight:height})
	}
	componentWillUnmount(){
		this.mounted = false
		window.removeEventListener('resize', this.handleResize)
	}
	
	count_as_string(number){
		if (number===0) return ''
		
		return '('+number.toString()+')'
	}
	render(){
		//title = <h4>Jobs <Badge pill>{this.props.my_calls.length + this.props.pending_calls.length +this.props.orders_shipping.length +this.props.orders_to_ship.length }</Badge></h4>
		//title = <h4>Geleverd <Badge pill>{this.props.orders_shipped.length}</Badge></h4>}
		return 	<Container id="ctrlcntr" style={{height:'100%'}} fluid className="px-0 py-0" >
					<Stack id="stackall" direction="vertical" gap={1} className="mx-auto px-0 py-0 flex-column" style={{maxWidth:'500px', height:'100%'}}>
						<Menu 
							OberJobCount={()=> this.props.my_calls.length + this.props.pending_calls.length +this.props.orders_shipping.length +this.props.orders_to_ship.length}
							OberJobCompleteCount={()=>this.props.orders_shipped.length}/>
						<Stack className="AppContent py-2" style={{height:this.state.windowHeight-8, overflow:'auto'}}>
						{this.props.menu==='obertasks' && this.render_obertasks()}
						{this.props.menu==='obercompleted' && this.render_obercompleted()}
						{this.props.menu==='monitororders' && this.render_monitorOrders()}
						</Stack>
					</Stack>
				</Container>	
					
	}
	
	render_obertasks() {
		return (
				<>
					{ 	this.props.my_calls.length===0 && 
						this.props.pending_calls.length===0 && 
						this.props.orders_shipping.length===0 &&
						this.props.orders_to_ship.length===0 &&
						<h5 style={{textAlign:'center'}}>Niks te doen...</h5>}
							
							{ this.props.my_calls.length!==0 && <h5 style={{textAlign:'center'}}>Op weg naar klant...</h5>}
							{ this.props.my_calls.length>0 &&
								<Carousel indicators={false} interval={null} variant="dark" className="px-5 mx-0" >
									{
										this.props.my_calls.map( call =>
											<Carousel.Item key={call.call_id}>
												<Card className="bg-transparent">
													<Card.Body>
														<Card.Text style={{textAlign:'center'}}>{call.user_name}@{call.location_name} vraagt hulp.{call.comment && call.comment.length && <div>Opm: {call.comment}</div>}</Card.Text>
														{call.position.length>0 && <Card.Text style={{textAlign:'center'}}>{call.position}</Card.Text>}
														<Call call_id={call.call_id} status={call.call_status} location={call.location_name} user_id={call.user_id} ober_name={call.ober_name} ober_identifier={call.ober_identifier} comment={call.comment} data={call}/>
											
													</Card.Body>
												</Card>
											</Carousel.Item>
										)
									}
								</Carousel>
							}
							{ this.props.pending_calls.length!==0 && <h5 style={{textAlign:'center'}}>Ober gevraagd</h5>}
							{ this.props.pending_calls.length>0 &&
								<Carousel indicators={false} interval={null} variant="dark" className="px-5 mx-0">
									{
										this.props.pending_calls.map( call =>
											<Carousel.Item key={call.call_id}>
												<Card className="bg-transparent">
													<Card.Body>
														<Card.Text style={{textAlign:'center'}}>{call.user_name}@{call.location_name} vraagt hulp.{call.comment && call.comment.length && <div>Opm: {call.comment}</div>}</Card.Text>
														{call.position.length>0 && <Card.Text style={{textAlign:'center'}}>{call.position}</Card.Text>}
														<Call call_id={call.call_id} status={call.call_status} location={call.location_name} user_id={call.user_id} ober_name={call.ober_name} ober_identifier={call.ober_identifier} comment={call.comment} data={call}/>
													</Card.Body>
												</Card>
											</Carousel.Item>
										)
									}
								</Carousel>
							}
							{ this.props.orders_shipping.length!==0 && <h5 style={{textAlign:'center'}}>Bestellingen aan het leveren</h5>}
							{ this.props.orders_shipping.length>0 &&
								<Carousel indicators={false} interval={null} variant="dark" className="px-5 mx-0">
									{
										this.props.orders_shipping.map( o =>
											<Carousel.Item key={o.order_id}>
												<Card className="bg-transparent">
													<Card.Body>
														<Card.Text style={{textAlign:'center'}}>#{o.order_number===null?o.order_id:o.order_number}@{o.bar} leveren aan {o.location}</Card.Text>
															{o.position.length>0 && <Card.Text style={{textAlign:'center'}}>{o.position}</Card.Text>}
														<Order order_id={o.order_id} order_number={o.order_number} order_amount={o.order_amount} status={o.order_status} orderType="ober" showStatus={false} order={o}/>
													</Card.Body>
												</Card>
											</Carousel.Item>
										)
									}
								</Carousel>
							}
							{ this.props.orders_to_ship.length!==0 && <h5 style={{textAlign:'center'}}>Bestellingen die klaar staan</h5>}
							{ this.props.orders_to_ship.length>0 &&
								<Carousel indicators={false} interval={null} variant="dark" className="px-5 mx-0">
								{
									this.props.orders_to_ship.map( o =>
										<Carousel.Item key={o.order_id}>
											<Card className="bg-transparent">
												<Card.Body>
												<Card.Text style={{textAlign:'center'}}>#{o.order_number===null?o.order_id:o.order_number}@{o.bar} klaar voor {o.location}</Card.Text>
												{o.comment && o.comment.length && <Card.Text style={{ width:'190px', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap', resize:'vertical'}}>Opm: {o.comment}</Card.Text>}
												<Order order_id={o.order_id} order_number={o.order_number} order_amount={o.order_amount} status={o.order_status} orderType="ober" showStatus={false} order={o}/>
												</Card.Body>
											</Card>
										</Carousel.Item>
									)
								}
								</Carousel>
							}
				</>		
					
		)
	}
	render_obercompleted() {
		return (
				
				<ListGroup className="px-1">
					{
						this.props.orders_shipped.map( order =>
						<ListGroup.Item className="px-0 py-0 mx-0 my-0 bg-transparent" key={order.order_id}>
							<Order order_id={order.order_id} order_number={order.order_number} order_amount={order.order_amount} status={order.order_status} orderType="ober" showStatus={false} showLoc={true} order={order} location={order.location}/>
						</ListGroup.Item>)
					}
				</ListGroup>
						
		)
	}
	render_monitorOrders(){
		/*return 	<Stack direction="vertical">
					{
						this.props.orders.map(order => <Order key={order.order_id}/>)
					}
				</Stack>*/
		return <ListGroup className="px-1 py-1 mx-0 my-0" style={{}}>
					{
						this.props.orders.map( order =>
							<ListGroup.Item className="px-0 py-0 mx-0 my-0 bg-transparent" key={order.order_id} >
								<Order order_id={order.order_id} order_number={order.order_number} order_amount={order.order_amount} location={order.location} status={order.order_status} orderType="monitor" order={order} showLoc/>
							</ListGroup.Item>)
					}
				</ListGroup>
				
	}

}

function mapDispatchToProps(dispatch) {
	return {
		/*clearBasket: () => {dispatch({ type: CLEAR_BASKET  })}*/
		
	}
}

function mapStateToProps(state) {
	//console.log("calls:"+JSON.stringify(state.app))
	return {
		orders_to_ship:state.app.barorders.filter(order => order.order_status===4).sort((a,b)=>parseInt(a.order_id)-parseInt(b.orderId)),
		orders_shipping:state.app.barorders.filter(order => order.order_status===5).filter(order=>order.ober_identifier===state.app.identifier).sort((a,b)=>parseInt(a.order_id)-parseInt(b.orderId)),
		orders_shipped:state.app.barorders.filter(order => order.order_status===6).sort((a,b)=>parseInt(a.order_id)-parseInt(b.orderId)),
		orders:state.app.barorders.sort((a,b)=>parseInt(b.order_id)-parseInt(a.order_id)),
		identifier:state.app.identifier,
		my_calls: state.app.calls.filter(call=>call.ober_identifier===state.app.identifier).sort((a,b)=>parseInt(a.call_id)-parseInt(b.call_id)),
		pending_calls: state.app.calls.filter(call=>call.ober_identifier===null).sort((a,b)=>parseInt(a.call_id)-parseInt(b.call_id)),
		menu:state.app.menu
		
	}
	
}
  
export default connect(mapStateToProps,mapDispatchToProps)(Ober); 