import React, {Component} from 'react'
import { connect } from 'react-redux'
import Container from 'react-bootstrap/Container'

import Consumer from './consumer'
import Bar from './bar'
import Ober from './ober'
import Monitor from './monitor'
import Admin from './admin'



class Main extends Component {
	
	static defaultProps = {roles:["consument"]}
	
	render() {
		//console.log("rendering Main")
		let ctrl
			
		if (this.props.roles.length===0)
		{
			//console.log("no roles...")
			ctrl = <>Oeps</>
		}
		else 
		{
			let role = this.props.role
			if (role === "consument") {
				ctrl = <Consumer/>
			}else if (role === "bar") {
				ctrl = <Bar/>
			}else if (role === "ober") {
				ctrl = <Ober/>
			}else if (role === "monitor") {
				ctrl = <Monitor/>
			}else if (role === "admin") {
				ctrl = <Admin/>
			}else {
				ctrl = <>{"TODO :  andere rollen"}</>
			} 
		}
		
		return (
			<Container id="main" fluid className="mx-0 px-0 py-0 flex-grow-1" style={{height:'100%'}}>
				{ctrl}
			</Container> 
		)
	}
}

function mapStateToProps(state) {
	return {roles:state.app.roles,role:state.app.role}
}
  
export default connect(mapStateToProps)(Main);  





