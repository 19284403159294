import {Component} from 'react'
import AmountCtrl from './amountctrl'
import Stack from 'react-bootstrap/Stack'
import {XLg, CheckCircle, /*ArrowCounterclockwise, Repeat*/} from 'react-bootstrap-icons'
import Button from 'react-bootstrap/Button'

export default class Article extends Component {
	
	
	
	
	static defaultProps = {onChange: (cnt )=>{}, count:0, outOfStock:false}
	format_price(price)
	{
		price = price.toString()
		if (price.includes('.')){
			price = price +'00'
			let pos = price.indexOf('.')
			price = price.substring(0,pos+3)
			price = price.replace('.', ',')
		}
		return price
	}
	render(){
		//console.log("A min-max:"+ this.props.minimum+ "/"+this.props.maximum)
		let name = this.props.info
		if (name===undefined || name===null || name==="")
		{
			name = this.props.name
		}
		if (this.props.outOfStock) name = <strike>{name}</strike>
		return 	<Stack className="my-0	mx-0" direction="horizontal" gap={2} >
					<span style={{width:'100%'}}><div className="px-1" style={{width:'100%', textAlign:'left'}}>{name}</div></span>
					{ 
						this.props.price && parseFloat(this.props.price) !== 0 && <span><div style={{width:'60px', textAlign:'right'}}>{this.format_price(this.props.price)} €</div></span>
					}
					{
						/*this.props.price && parseInt(this.props.price) == 0 && <span><div style={{width:'75px', textAlign:'right'}}>Inbegrepen</div></span>*/
					}
					{ this.props.type!=="bar" && <div style={{marginRight:5}}><AmountCtrl count={this.props.count} onChange={this.props.onChange} minimum={this.props.minimum} maximum={this.props.maximum}/></div>}
					{ this.props.type==="bar" && 
						<div style={{marginRight:5}}>
							<Button variant={this.props.outOfStock?"danger":"success"} className="p-0" onClick={()=>this.props.onStockChange(this.props.outOfStock)}>
								{!this.props.outOfStock && <CheckCircle size='35'/>}
								{this.props.outOfStock && <XLg size='35'/>}
							</Button>
						</div>
					}
					
				</Stack>
	}
	
	
}
