import React, {Component} from 'react'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import {api_call_waiter} from './api'
import {ADD_NOTIFICATION} from './actions'


class OberCall extends Component {
	
	constructor(props) {
		super(props)
		this.askOber = this.askOber.bind(this)
		this.callWaiter = this.callWaiter.bind(this)
		this.state={showAskWaiterQuestion:false, comment:""}
		
	}
	componentDidMount() {
		const { childRef } = this.props;
		childRef(this);
	}
	componentWillUnmount() {
		const { childRef } = this.props;
		childRef(undefined);
	}
	
	render() {
		return (<Modal show={this.state.showAskWaiterQuestion} onHide={()=>this.setState({showAskWaiterQuestion:false})} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>Ober roepen</Modal.Title>
						</Modal.Header>
						<Modal.Body>Wenst u hulp of uw saldo te verhogen of te verlagen? Een ober komt graag bij u langs.</Modal.Body>
						<Modal.Footer>
							<Form.Control as="input" type="text" value={this.state.comment} onChange={(e)=>this.setState({comment:e.target.value})} placeholder="Opmerking..."/>
							<Button variant="success" onClick={()=>{
								this.setState({showAskWaiterQuestion:false}); 
								this.callWaiter(this.props.identifier, this.props.location, this.state.comment)}
								}>Ober roepen</Button>
						</Modal.Footer>
				</Modal>
		)
	}
	
	askOber() {
		this.setState({showAskWaiterQuestion:true})
	}
	
	callWaiter(identifier, location, comment) {
		api_call_waiter(identifier, location, comment)
		.then (result => {
			if (result.result){
				this.props.addNotification({title:'Ober hulp', message:"Een ober komt dadelijk naar je toe."})
				this.setState({comment:""})
			} else {
				this.props.addNotification({title:'Ober melding', message:result.error.msg})
			}
			
				
		})
		.catch (err => {
			console.log(err)
			this.props.addNotification({title:'Ober probleem...', message:err.toString()})
			
		})
	}
	
}

	
function mapDispatchToProps(dispatch) {
	return {
		addNotification: (notification) => {dispatch({ type: ADD_NOTIFICATION, notification  })}
	}
}

function mapStateToProps(state) {
	return {identifier:state.app.identifier,location: state.user.location}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(OberCall);  





