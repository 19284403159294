import {Component} from 'react'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import Badge from 'react-bootstrap/Badge'
import {XLg, CheckCircle, PauseFill, CartDash, Pencil} from 'react-bootstrap-icons'
import {api_unprepare_order, api_pause_order, api_finished_order, api_delivered_order, api_order_details} from './api'
import {format_price} from './util'


class OrderDetails extends Component {
	
	constructor(props)
	{
		super(props)
		this.state={order:[],showRefundButton:false}
		this.mounted = false
		this.loadDetails = this.loadDetails.bind(this)
	}
	
	static defaultProps={articles:[]}
	
	format_price(price)
	{
		if (price===undefined || price===null) return ''
		price = price.toString()
		if (price.includes('.')){
			price = price +'00'
			let pos = price.indexOf('.')
			price = price.substring(0,pos+3)
			price = price.replace('.', ',')
		}
		return price
	}
	
	componentWillUnmount(){
		this.mounted = false
	}
	
	componentDidMount(){
		this.mounted= true
		this.loadDetails()
	}
	
	loadDetails(){
		//console.log("loading orderdetails")
		api_order_details(this.props.order_id)
		.then( order => {
			//console.log("got new orderdetails:"+JSON.stringify(order))
			if (this.mounted){
				this.setState({order})
			}
		})
		.catch(err => console.log(err))
	}
	
	render(){
		let loc = "Ergens..."
		if (this.state.order && this.state.order.order) loc = this.state.order.order.location
		//console.log("comment:"+JSON.stringify(this.state.order.comment))
		
		let fooi 
		if ( this.state.order && this.state.order.order)
			fooi = this.state.order.order.fooi_amount
		
		if (fooi>0)
			fooi = <sup><Badge pill bg="danger">{format_price(fooi)}€</Badge></sup>
		else 
			fooi = undefined
		
				
		return 	<Card>
					<Card.Header className="px-1">#{this.props.order_number===null?this.props.order_id:this.props.order_number} - {loc}{fooi}</Card.Header>
					<Card.Body className="px-1 py-1">
						<ListGroup>
						{
							this.props.articles && this.props.articles.length>0 && this.state.order.details && this.state.order.details.map( orderline => 
								{
									let article = this.props.articles.find( art => art.article_id === orderline.article_id)
									if (article && article.bar_article){
										return (<ListGroup.Item key={orderline.orderline_id}>
													<div style={{textAlign:'left', padding:'0', margin:'0'}}>
													{ 
														this.props.refundable && this.state.showRefundButton && orderline.count > 0 &&  
														<Button variant="warning" className="p-0 m-0" onClick={()=>this.props.Refund(orderline, this.loadDetails)}>
															<CartDash size='25'/>
														</Button>
													}
													{" "+orderline.count} x {article.name}
													</div>
												</ListGroup.Item>)
									}
									else
										return undefined
								})
						}
						</ListGroup>
						{this.props.comment && this.props.comment.length>0 && <div style={{}}>Opm: {this.props.comment}</div>}
					</Card.Body>
					<Card.Footer className="px-1">
						<Button className="mx-1 my-1" variant="danger" onClick={()=>this.cancelMakeOrder(this.props.order_id)}><XLg size='1.5em'/></Button>
						<Button className="mx-1 my-1" variant="primary" onClick={()=>this.setState({showRefundButton:!this.state.showRefundButton})}><Pencil size='25'/></Button>
						<Button className="mx-1 my-1" onClick={()=>this.pauseOrder(this.props.order_id)}><PauseFill size='1.5em'/></Button>
						<Button className="mx-1 my-1" variant="success" onClick={()=>this.finalizeOrder(this.props.order_id)}><Image src="ober.png"  style={{height:25}}/></Button>
						<Button className="mx-1 my-1" variant="success" onClick={()=>this.deliveredOrder(this.props.order_id)}><CheckCircle size='25'/></Button>
						
					</Card.Footer>
				</Card>
	}
	
	
	cancelMakeOrder(order_id) {
		api_unprepare_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	pauseOrder(order_id) {
		api_pause_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	finalizeOrder(order_id) {
		api_finished_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	deliveredOrder(order_id) {
		api_delivered_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	
}


function mapStateToProps(state) {
	return {identifier:state.app.identifier, articles:state.app.articles}
}
  
export default connect(mapStateToProps)(OrderDetails);  
