import React, {Component, createRef} from 'react'
import { connect } from 'react-redux'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
//import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import Nav from 'react-bootstrap/Nav'
//import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'
import {PersonCircle} from 'react-bootstrap-icons'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Badge from 'react-bootstrap/Badge'
//import Modal from 'react-bootstrap/Modal'
//import Table from 'react-bootstrap/Table'

import LocationSelection from './locationselection'
import {SET_MENU, SET_ROLE, LOGOUT, SET_LOCATION} from './actions'
import {ws_disconnect} from './web'


class Menu extends Component {
	
	static defaultProps={OberJobCount:()=>{}, OberJobCompleteCount:()=>{},BarJobCount:()=>{}, BarJobCompleteCount:()=>{}}
	
	constructor(props) {
		super(props)
		this.state={positionedLocations:[]}
		this.SelectLocation = this.SelectLocation.bind(this)
		this.refLocSel = createRef()
		//console.log("c'tor: refLocSel="+JSON.stringify(this.refLocSel))
	}
	
	format_price(price)
	{
		price = price.toString()
		if (price.includes('.')){
			price = price +'00'
			let pos = price.indexOf('.')
			price = price.substring(0,pos+3)
			price = price.replace('.', ',')
		}
		return price
	}
	
	render() {
		//console.log("locations:"+JSON.stringify(this.props.locations))
		let kaart = "Kaart"
		if (this.props.menu==="bestellen") kaart=<u>{kaart}</u>
		
		//let saldo = "Saldo("+this.format_price(this.props.credits)+"€)"
		let saldo = "Saldo"
		if (this.props.menu==="saldo") saldo=<u>{saldo}</u>
		
		let bestellingen = "Bestellingen"
		if (this.props.menu==="bestellingen") bestellingen=<u>{bestellingen}</u>
		
		let obertasks = "Opdrachten"
		let cnt = this.props.OberJobCount()
		if (cnt && cnt > 0)
			obertasks = <>{obertasks}<Badge pill>{cnt}</Badge></>
		if (this.props.menu==="obertasks") obertasks=<u>{obertasks}</u>
		
		let obercompleted = "Afgewerkt"
		cnt = this.props.OberJobCompleteCount()
		if (cnt && cnt > 0)
			obercompleted = <>{obercompleted}<Badge pill>{cnt}</Badge></>
		if (this.props.menu==="obercompleted") obercompleted=<u>{obercompleted}</u>
		
		let bartasks = "Opdrachten"
		cnt = this.props.BarJobCount()
		if (cnt && cnt > 0)
			bartasks = <>{bartasks}<Badge pill>{cnt}</Badge></>
		if (this.props.menu==="bartasks") bartasks=<u>{bartasks}</u>
		
		let barcompleted = "Afgewerkt"
		cnt = this.props.BarJobCompleteCount()
		if (cnt && cnt > 0)
			barcompleted = <>{barcompleted}<Badge pill>{cnt}</Badge></>
		if (this.props.menu==="barcompleted") barcompleted=<u>{barcompleted}</u>
		
		let monitorcredits = "Saldo"
		if (this.props.menu==="monitorcredits") monitorcredits=<u>{monitorcredits}</u>
		
		let monitororders = "Bestellingen"
		if (this.props.menu==="monitororders") monitororders=<u>{monitororders}</u>
		
		let barmenu = "Menu"
		if (this.props.menu==="barmenu") barmenu=<u>{barmenu}</u>
		
		let admindashboard = "Dashboard"
		if (this.props.menu==="admindashboard") admindashboard=<u>{admindashboard}</u>
		
		const role = this.props.role
		
		return (
			<Navbar bg="red" className="p-0 m-0" >
			<Container className="p-0 m-0" fluid>
					<Navbar.Brand className="p-0 m-0"><a href="http://www.ttcdepinte.be"> <Image src="logo.png" style={{width:50}} /></a></Navbar.Brand>
					<Stack direction="vertical" className="p-0 m-0">
					<Stack direction="horizontal" className="p-0 my-0" style={{height:'40px'}}>
							{	
								<NavDropdown title={<PersonCircle style={{color:'yellow'}}/>} id="nav-dropdown" className="p-0 m-0">
									{	this.props.roles.length>1 && 
										<>
											{this.props.roles.map(role => <NavDropdown.Item key={role} className="" eventKey={role} onClick={()=>{this.props.setRole(role)}}>{role}</NavDropdown.Item>)}
											<NavDropdown.Divider />
										</>
									}
									<NavDropdown.Item eventKey="4.4" onClick={this.props.logout}>Uitloggen</NavDropdown.Item>
								</NavDropdown>
							}
							<Container fluid className="p-0 m-0">
							<Navbar.Text className="p-0 m-0" style={{color:'yellow', width:'100%'}}>
								<Container fluid className="p-0 m-0">
									<Row className="mx-auto justify-content-center flex-grow-1 p-0">		
										<Stack direction='vertical' gap={0}>
											<div style={{ width:'162px', textAlign:'left', overflow:'hidden', textOverflow:'ellipsis', fontSize:'10px', whiteSpace:'nowrap'}}>{this.props.username}</div>
												{	role==="consument" &&	
													<div style={{width:'162px', textAlign:'left', overflow:'hidden', textOverflow:'ellipsis', fontSize:'12px', whiteSpace:'nowrap'}}>
														{this.props.roles.includes("ober") && <>{"@ "}<Button style={{padding:'0px', height:'26px'}} onClick={this.SelectLocation}>{this.props.location || "Locatie niet gekend"}</Button></>}
														{!this.props.roles.includes("ober") && <>@{this.props.location || "Locatie niet gekend"}</>}
													</div>
												}
										</Stack>
									</Row>
								</Container>
							</Navbar.Text>
							</Container>
					</Stack>
						<Nav className="p-0 my-0 mx-auto" style={{}}>
							{role==="consument" && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('bestellen') } style={{ color:'#ffff00'}}>{kaart}</Nav.Link>}
							{role==="consument" && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('saldo')} style={{ color:'#ffff00'}}>{saldo}</Nav.Link>}
							{role==="consument" && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('bestellingen')} style={{ color:'#ffff00'}}>{bestellingen}</Nav.Link>}
							{role==="ober" && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('obertasks')} style={{ color:'#ffff00'}}>{obertasks}</Nav.Link>}
							{role==="ober" && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('obercompleted')} style={{ color:'#ffff00'}}>{obercompleted}</Nav.Link>}
							{role==="bar" && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('bartasks')} style={{ color:'#ffff00'}}>{bartasks}</Nav.Link>}
							{role==="bar" && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('barcompleted')} style={{ color:'#ffff00'}}>{barcompleted}</Nav.Link>}
							{role==="bar" && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('barmenu')} style={{ color:'#ffff00'}}>{barmenu}</Nav.Link>}
							{role==="monitor" && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('monitorcredits')} style={{ color:'#ffff00'}}>{monitorcredits}</Nav.Link>}
							{(role==="monitor" || role==="ober") && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('monitororders')} style={{ color:'#ffff00'}}>{monitororders}</Nav.Link>}
							{role==="admin" && <Nav.Link className="py-0 px-1" onClick={()=>this.props.setMenu('admindashboard')} style={{ color:'#ffff00'}}>{admindashboard}</Nav.Link>}
			</Nav>
					</Stack>
							
					{	role==="consument" && 
						<Navbar.Text className="m-0 px-1" style={{fontSize:'12px', textAlign:'right'}}>
							<Image src="raise.png"  style={{width:30, color:'red'}} onClick={this.props.askOber}/>
						</Navbar.Text>
					}
			</Container>
			{/*
			<Modal show={this.state.selectLocation} onHide={()=>this.setState({selectLocation:false})} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
						<Modal.Header closeButton>
							<Modal.Title>Selecteer de locatie</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Table borderless size="sm" className="p-0 m-0">
								<tbody className="p-0 m-0">
								{
									this.state.positionedLocations.map( (locRow,r) => {
										return (
												<tr key={r} className="p-0 m-0" style={{height:'1em'}}>
													{
														locRow.map((loc,c)=> {
															if (loc.name==null) return <td key={c} className="p-0 m-0"/>
															return (<td key={c} className="p-1 m-0">
																		<Button className="p-1 m-0" style={{width:'100%'}} onClick={()=>{this.props.setLocation(loc.name);this.setState({selectLocation:false})}}>
																			{loc.name}
																		</Button>
																	</td>)
														})
													}
												</tr>
												)
									})
								}
								</tbody>
							</Table>
						</Modal.Body>
						
			</Modal>*/}
			<LocationSelection childRef={ref =>(this.refLocSel=ref)} />
			</Navbar>
			
			
			
	
		)
	}
	
	SelectLocation(){
		/*let locs=[]
		let minRow=1000
		let maxRow=-1000
		let minCol=1000
		let maxCol=-1000
		this.props.locations.forEach(loc => {
			
			if (loc.col==null || loc.row==null) return
			
			if (loc.col<minCol)
				minCol = loc.col
			if (loc.col>maxCol)
				maxCol = loc.col
			if (loc.row<minRow)
				minRow = loc.row
			if (loc.row>maxRow)
				maxRow = loc.row
		})
		for(var r = minRow; r<=maxRow ; r++)
		{
			let locCols=[]
			for(var c = minCol; c<=maxCol ; c++)
			{
				locCols.push({})
			}
			locs.push(locCols)
		}
		this.props.locations.forEach(loc => {
			if (loc.col==null || loc.row==null) return
			
			locs[loc.row-minRow][loc.col-minCol]=loc
		})
		
		console.log("positionedLocations:"+JSON.stringify(locs))
		this.setState({selectLocation:true, positionedLocations:locs})*/
		//console.log("use: refLocSel="+JSON.stringify(this.refLocSel))
		if (this.refLocSel) 
			this.refLocSel.SelectLocation()
	}
	
	
}
function mapDispatchToProps(dispatch) {
	return {
		setMenu: (menu) => {dispatch({ type: SET_MENU, menu  })},
		setRole: (role) => {dispatch({ type: SET_ROLE, role})},
		logout: () => {dispatch({type:LOGOUT}); ws_disconnect();},
		setLocation: (location) => dispatch({type:SET_LOCATION, location})
	}
}

function mapStateToProps(state) {
	return {
		location: state.user.location, 
		locations: state.app.locations, 
		credits:state.app.credits, 
		menu:state.app.menu, 
		roles:state.app.roles, 
		role:state.app.role,
		username:state.app.username,
		identifiers:(state.user.identifiers || {})
		}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Menu);  





