import {Component} from 'react'
import Container from 'react-bootstrap/Container'
import Stack from 'react-bootstrap/Stack'
import {Plus,Dash} from 'react-bootstrap-icons'

export default class AmountCtrl extends Component {
	constructor(props){
		super(props)
		this.increment = this.increment.bind(this)
		this.decrement = this.decrement.bind(this)
	}
	
	static defaultProps = {minimum:0, maximum:100}
	
	componentDidUpdate(){
		if (this.props.count>this.props.maximum)
			this.props.onChange(this.props.maximum)
		if (this.props.count<this.props.minimum)
			this.props.onChange(this.props.minimum)
	}
	
	render(){
		return 	<Container className="rounded-pill App px-0" >	
					<Stack  direction="horizontal" gap={1} className="rounded-pill">
						<div className="" style={{width:40}}><Dash style={{color:'white'}} size='2.5em' disabled={this.props.count<=this.props.minimum} onClick={this.decrement}/></div>
						<div className="AppContent" style={{width:35, textAlign: "center", borderRadius:'0', fontSize:25}}><strong>{this.props.count}</strong></div>
						<div className="" style={{width:40}}><Plus style={{color:'white'}} size='2.5em' disabled={this.props.count>=this.props.maximum} onClick={this.increment}/></div>
					</Stack>
				</Container>
	}
	
	decrement() {
		let count = this.props.count-1
		if (count<this.props.minimum )
			count = this.props.minimum
		this.props.onChange(count)
	}
	
	increment() {
		let count = this.props.count+1
		if (count > this.props.maximum)
			count = this.props.maximum
		this.props.onChange(count)
	}
}