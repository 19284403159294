import {Component} from 'react'
import { connect } from 'react-redux'

import ListGroup from 'react-bootstrap/ListGroup'
//import Container from 'react-bootstrap/Container'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Menu from './menu'
import Order from './order'
import {format_price} from './util' 

class Monitor extends Component {
	
	static statusses = ["Besteld","Geannuleerd","In productie","Gepauzeerd","Klaar","Op weg","Geleverd"]
	
	constructor(props)
	{
		super(props)
		this.state={windowHeight:0, showPayments:false}
		this.handleResize = this.handleResize.bind(this)
		this.mounted = false
		this.showPaymentDetails=this.showPaymentDetails.bind(this)
	}
	
	componentDidMount(){
		this.mounted = true
		
		window.addEventListener('resize', ()=>{ this.handleResize()})
		this.handleResize()
	}
	
	handleResize(){
		const {  innerHeight: height } = window
		if (this.mounted)
			this.setState({windowHeight:height})
	}
	
	componentWillUnmount(){
		this.mounted = false
		window.removeEventListener('resize', this.handleResize)
	}
	
	format_price(price)
	{
		if (price===null || price ===undefined)
			price=0
		price = price.toString()
		if (price.includes('.')){
			price = price +'00'
			let pos = price.indexOf('.')
			price = price.substring(0,pos+3)
			price = price.replace('.', ',')
		}
		return price
	}
	
	render(){
		
		return < >
					<Stack id="stackall" direction="vertical" gap={1} className="mx-auto px-0 py-0 flex-column" style={{height:'100%'}}>
						<Menu />
						<Stack className="AppContent py-2" style={{height:this.state.windowHeight-8, overflow:'auto'}}>
						{this.props.menu==='monitorcredits' && this.render_monitorCredits()}
						{this.props.menu==='monitororders' && this.render_monitorOrders()}
						</Stack>
					</Stack>
					<Modal show={this.state.showPayments} onHide={()=>{this.setState({showPayments:false})}} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>Ober Cash</Modal.Title>
						</Modal.Header>
						<Modal.Body className=" p-1" >
						<Container id="ctrlcntr2" style={{height:'100%'}} fluid className="px-0 py-0" >
							<ListGroup style={{width:'100%'}}>
								{
									this.props.data.payments && this.props.data.payments.map( payment => 
									{
										return <ListGroup.Item style={{width:'100%'}} key={payment.name}> 
										<Stack direction="horizontal" gap={3} className="mx-auto px-1 py-1" >
											<div style={{textAlign:'right', width:'50%'}}>{payment.name}</div>
											<div style={{textAlign:'left', width:'50%'}}>{this.format_price(payment.sum)}€</div>
										</Stack>
										</ListGroup.Item>
										
											
									})
								}
							</ListGroup>
						</Container>
						</Modal.Body>
						<Modal.Footer>
						<Container id="ctrlcntr2" style={{height:'100%'}} fluid className="px-0 py-0" >
							<Stack direction="horizontal" gap={3} className="mx-auto px-1 py-0" >
								<div style={{textAlign:'left', width:'100%'}}>
									<b>Total Cash: {this.format_price(this.props.data.saldo)}€</b>
								</div>
								<div style={{textAlign:'right'}}>
									<Button variant="success" onClick={()=>{this.setState({showPayments:false}) }}>OK</Button>
								</div>
							</Stack>
						</Container>
						</Modal.Footer>
					</Modal>
				</>
	}
	
	render_monitorCredits(){
		
		return 	<Stack direction="vertical" style={{textAlign:'right'}}>
					<Stack direction="horizontal" gap={1}>
						<div style={{width:'50%',textAlign:'right'}}>Saldo TTC:</div>
						<div style={{width:'50%',textAlign:'left'}}><Button onClick={this.showPaymentDetails}>{format_price(this.props.data.saldo)}€</Button></div>
					</Stack>
					<Stack direction="horizontal" gap={1}>
						<div style={{width:'50%',textAlign:'right'}}>Saldo Klanten:</div>
						<div style={{width:'50%',textAlign:'left'}}>{format_price(this.props.data.credits)}€</div>
					</Stack>
					<Stack direction="horizontal" gap={1}>
						<div style={{width:'50%',textAlign:'right'}}>Totaal Orderbedrag:</div>
						<div style={{width:'50%',textAlign:'left'}}>{format_price(this.props.data.order_amount)}€</div>
					</Stack>
					<Stack direction="horizontal" gap={1}>
						<div style={{width:'50%',textAlign:'right'}}>Totaal Fooibedrag:</div>
						<div style={{width:'50%',textAlign:'left'}}>{format_price(this.props.data.fooi_amount)}€</div>
					</Stack>
					<Stack direction="horizontal" gap={1}>
						<div style={{width:'50%',textAlign:'right'}}>Aantal Orders:</div>
						<div style={{width:'50%',textAlign:'left'}}>{this.props.data.orders}</div>
					</Stack>
					
				</Stack>
				
				
				
				
	}
	showPaymentDetails(){
		this.setState({showPayments:true})
		
	}
	render_monitorOrders(){
		/*return 	<Stack direction="vertical">
					{
						this.props.orders.map(order => <Order key={order.order_id}/>)
					}
				</Stack>*/
		return <ListGroup className="px-1 py-1 mx-0 my-0" style={{}}>
					{
						this.props.orders.map( order =>
							<ListGroup.Item className="px-0 py-0 mx-0 my-0 bg-transparent" key={order.order_id} >
								<Order order_id={order.order_id} order_number={order.order_number} order_amount={order.order_amount} location={order.location} status={order.order_status} orderType="monitor" order={order} showLoc/>
							</ListGroup.Item>)
					}
				</ListGroup>
				
	}
	
	
}


function mapDispatchToProps(dispatch) {
	return {
		/*clearBasket: () => {dispatch({ type: CLEAR_BASKET  })}*/
		
	}
}

function mapStateToProps(state) {
	console.log("dasjboard data:"+JSON.stringify(state.app.dashboard))
	return {
		data:state.app.dashboard,
		menu:state.app.menu,
		orders:state.app.barorders.sort((a,b)=>parseInt(b.order_id)-parseInt(a.order_id))
		
	}
	
}
  
export default connect(mapStateToProps,mapDispatchToProps)(Monitor); 