	
export function	format_price(price){
	if (isNaN(price)) price=0
	price = make_price(price).toString()
	if (price.includes('.')){
		price = price +'00'
		let pos = price.indexOf('.')
		price = price.substring(0,pos+3)
		price = price.replace('.', ',')
	}
	return price
}
	
export function make_price(price){
	let neg = price < 0
	if (neg) price = -price
	price =  parseFloat(parseInt((parseFloat(price)*100)+0.5)/100)
	if (neg) price = -price
	return price
}

export function get_fooi(orderlines, articles){
		let fooi = 0
		if (orderlines && articles){
			orderlines.map( line =>{
				let article = articles.find( art =>  art.article_id === line.article_id)
				if (article && !article.bar_article)
					fooi += line.count * article.price;
				return 0
			})
		}
		return make_price(fooi)
	}