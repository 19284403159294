import {Component} from 'react'
import { connect } from 'react-redux'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Badge from 'react-bootstrap/Badge'
import ListGroup from 'react-bootstrap/ListGroup'
import {XLg, CheckCircle, /*ArrowCounterclockwise, Repeat*/} from 'react-bootstrap-icons'
import Image from 'react-bootstrap/Image'
import { api_cancel_order, api_prepare_order, api_unprepare_order, api_pause_order, 
	api_finished_order, api_cancelfinished_order, api_ship_order, api_order_details,
	api_delivered_order, api_remake_order, api_return_order} from './api'
	
import { show_vakjes }  from './config'


class Order extends Component {
	
	
	static statusses = ["Besteld","Geannuleerd","In productie","Gepauzeerd","Klaar","Op weg","Geleverd","Niet betaald","Betaling Geannuleerd"]
	
	
	constructor(props)
	{
		super(props)
		this.cancelOrder = this.cancelOrder.bind(this)
		this.showOrderDetails = this.showOrderDetails.bind(this)
		this.askCancelOrderConfirmation = this.askCancelOrderConfirmation.bind(this)
		this.state={showDetails:false, order:[]}
	}
	
	format_price(price)
	{
		if (price===undefined || price===null) return ''
		price = price.toString()
		if (price.includes('.')){
			price = price +'00'
			let pos = price.indexOf('.')
			price = price.substring(0,pos+3)
			price = price.replace('.', ',')
		}
		return price
	}
	
	render(){
		let fooi 
		if ( this.props.order )
			fooi = this.props.order.fooi_amount
		
		if (fooi>0)
			fooi = <sup><Badge pill bg="danger">{this.format_price(fooi)}€</Badge></sup>
		else 
			fooi = undefined
		
		let orderBtnText = '#'+this.props.order_id
		if (this.props.order_number !== null && this.props.order_number !== undefined)
			orderBtnText = '#'+this.props.order_number
		//if (this.props.orderType==='monitor'){
		//	orderBtnText += '('+this.format_price(this.props.order_amount)+'€)'
		//}
		let vakjes = (parseFloat(this.props.order_amount)*100)/25
		let add_vakjes = show_vakjes?" / "+vakjes+" vakjes":"";
		return 	<>
					<Stack direction="horizontal" gap={1}>
						<div ><Button variant="info" style={{width:100}} onClick={this.showOrderDetails}><div>{orderBtnText}{fooi}</div></Button></div>
						{(this.props.orderType==="monitor") && <div >{this.format_price(this.props.order_amount)}€</div>}
						{(this.props.orderType==="monitor") && <div >{' : '}</div>}
						{/*(this.props.orderType==="monitor") && <span style={{width:'100%'}}>{this.props.location}</span>*/}
						{this.props.showStatus!==false && <div style={{width:'100%'}}>{Order.statusses[this.props.status]}</div>}
						{this.props.showStatus===false && <div style={{width:'100%'}}>{''}</div>}
						{this.props.showLoc && <div style={{width:'100%'}}>{this.props.location}</div>}
						<div>
						{(this.props.orderType==="consument") && (this.props.status===0) && <div ><Button variant="danger" onClick={this.askCancelOrderConfirmation}>Annuleer</Button></div>}
						{(this.props.orderType==="ober") && (this.props.status===4) && <div><Button variant="success" className="p-0" onClick={()=>this.shipOrder(this.props.order_id)}><Image src="ober.png"  style={{height:35}}/></Button></div>}
						<Stack direction="horizontal" gap={2}>
							{(this.props.orderType==="ober") && (this.props.status===5) && <div><Button variant="danger" className="p-0" onClick={()=>this.finalizeOrder(this.props.order_id)}><XLg size='35'/></Button></div>}
							{/*(this.props.orderType==="ober") && (this.props.status===5) && <div><Button variant="danger" className="p-0" onClick={()=>this.remakeOrder(this.props.order_id)}><ArrowCounterclockwise size='35'/></Button></div>*/}
							{(this.props.orderType==="ober") && (this.props.status===5) && <div><Button variant="warning" className="p-0" onClick={()=>this.returnOrder(this.props.order_id)}><Image src="return.png"  style={{height:35}}/></Button></div>}
							{(this.props.orderType==="ober") && (this.props.status===5) && <div><Button variant="success" className="p-0" onClick={()=>this.deliveredOrder(this.props.order_id)}><CheckCircle size='35'/></Button></div>}
						</Stack>
						{/*(this.props.orderType==="ober") && (this.props.status===6) && <div><Button variant="danger" onClick={()=>this.returnOrder(this.props.order_id)}>Klacht</Button></div>*/}
						<Stack direction="horizontal" gap={2}>
							{(this.props.orderType==="bar") && (this.props.status===0) && <div><Button variant="danger" onClick={this.askCancelOrderConfirmation}>Annuleer</Button></div>}
							{(this.props.orderType==="bar") && (this.props.status===0) && <div><Button variant="success" onClick={()=>this.prepareOrder(this.props.order_id)}>Maak</Button></div>}
						</Stack>
						<Stack direction="horizontal" gap={2}>
							{(this.props.orderType==="bar") && (this.props.status===2) && <div style={{minWidth:'90px'}}><Button variant="danger" onClick={()=>this.cancelMakeOrder(this.props.order_id)}>In de lijst</Button></div>}
							{(this.props.orderType==="bar") && (this.props.status===2) && <div><Button onClick={()=>this.pauseOrder(this.props.order_id)}>Pauze</Button></div>}
							{(this.props.orderType==="bar") && (this.props.status===2) && <div><Button variant="success" onClick={()=>this.finalizeOrder(this.props.order_id)}>Klaar</Button></div>}
						</Stack>
						{(this.props.orderType==="bar") && (this.props.status===3) && <div><Button onClick={()=>this.prepareOrder(this.props.order_id)}>Maak</Button></div>}
						<Stack direction="horizontal" gap={2}>
							{(this.props.orderType==="bar") && (this.props.status===4) && <div><Button variant="danger" onClick={()=>this.cancelFinalizedOrder(this.props.order_id)}>Recall</Button></div>}
							{(this.props.orderType==="bar") && (this.props.status===4) && <div><Button variant="success" onClick={()=>this.shipOrder(this.props.order_id)}>Weg</Button></div>}
						</Stack>
						</div>
					</Stack>
					<Modal show={this.state.showDetails} onHide={()=>this.setState({showDetails:false})} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>Bestelling #{this.props.order_number===null?this.props.order_id:this.props.order_number} - {this.format_price(this.props.order_amount)}€{fooi}{add_vakjes}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ListGroup style={{}}>
								{
									this.state.order.details && this.state.order.details.map( orderline => 
									{
										let article = this.props.articles.find( art => art.article_id === orderline.article_id)
										if (article && article.bar_article){
											return 	<ListGroup.Item key={orderline.orderline_id}> {orderline.count} x {article.name}	</ListGroup.Item>
										}
										else
											return undefined
											
									})
								}
							</ListGroup>
						</Modal.Body>
						<Modal.Footer>
							{this.state.order && this.state.order.order && this.state.order.order.comment && this.state.order.order.comment.length>0 && <div style={{width:'100%'}}>Opm: {this.state.order.order.comment}
							<br/></div>}
							Status: {Order.statusses[this.props.status]}
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.showCanceOrderQuestion} onHide={()=>this.setState({showCanceOrderQuestion:false})} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>Bestelling annuleren</Modal.Title>
						</Modal.Header>
						<Modal.Body>Wil je deze bestelling annuleren(nu het nog kan)?</Modal.Body>
						<Modal.Footer>
							<Button variant="danger" onClick={()=>this.setState({showCanceOrderQuestion:false})}>Nee</Button>
							<Button variant="success" onClick={()=>{this.setState({showCanceOrderQuestion:false}); this.cancelOrder(this.props.order_id)}}>Ja</Button>
						</Modal.Footer>
					</Modal>
					
				</>
	}
	
	askCancelOrderConfirmation() {
		this.setState({showCanceOrderQuestion:true})
	}
	
	showOrderDetails() {
		api_order_details(this.props.order_id)
		.then( order => this.setState({order, showDetails:true}))
		.catch(err => console.log(err))
	}
	
	cancelOrder(order_id) {
		api_cancel_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	prepareOrder(order_id) {
		api_prepare_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	cancelMakeOrder(order_id) {
		api_unprepare_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	pauseOrder(order_id) {
		api_pause_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	finalizeOrder(order_id) {
		api_finished_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	cancelFinalizedOrder(order_id) {
		api_cancelfinished_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	shipOrder(order_id) {
		api_ship_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	deliveredOrder(order_id) {
		api_delivered_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	remakeOrder(order_id) {
		api_remake_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	returnOrder(order_id) {
		api_return_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	
	
}


function mapStateToProps(state) {
	return {identifier:state.app.identifier, articles:state.app.articles}
}
  
export default connect(mapStateToProps)(Order);  
