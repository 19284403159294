import {Component} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export default class ModalEx extends Component {
	
	static defaultProps = {
		onClose: (result)=>{}, 
		buttons:[{'variant':'danger', 'caption':'Nee', 'data':'no'},{'variant':'success', 'caption':'Ja', 'data':'yes'}]
		}
	
	render(){
		return 	<Modal show={this.props.show} onHide={()=>this.props.onClose()} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
					<Modal.Header closeButton>
						<Modal.Title>{this.props.title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{this.props.body}</Modal.Body>
					<Modal.Footer>
						{
							this.props.buttons.map( (btn,idx) => 
								<Button key={idx} variant={btn.variant} onClick={ () => this.props.onClose(btn.data, btn.keepopen) } >{btn.caption}</Button>
								)
						}
					</Modal.Footer>
				</Modal>
				
	}
}
