import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import {order, user, app, connection} from './reducers'

const rootReducer = combineReducers({ order, user, app, connection })

const persistConfig = {
  key: 'ttc-ober2024',
  storage,
  whitelist:['order','user']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer)
export const persistor = persistStore(store)