import './App.css';
import {Component, createRef} from 'react'
import { connect } from 'react-redux'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { QRCode } from "react-qr-svg"
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'

import Menu from './menu'
import OrderSummary from './ordersummary'
import MenuKaart from './menukaart'
import OrderHistory from './orderhistory'
import CreditsSelector from './creditsselector'
import Notifications from './notifications'
import OberCall from './obercall'
import ScreenSaver from './screensaver'

import {api_categories, api_articles, api_create_transfer, api_show_welcome, api_get_payments} from './api'
import {local, get_server_url} from './web'


class Consumer extends Component {

	static defaultProps={onOrder:()=>{}, onClear:()=>{}}
	
	constructor(props) {
		super(props)
		this.state={share:null, amount:0,windowHeight:0,showWelcome:this.props.showWelcome, transactions:undefined}
		this.handleResize = this.handleResize.bind(this)
		this.resetScreenSaverTimer = this.resetScreenSaverTimer.bind(this)
		this.enableScreenSaver = this.enableScreenSaver.bind(this)
		this.oberCallRef = createRef()
		this.refCredSel = createRef()
		this.showTransactions = this.showTransactions.bind(this)
		this.mounted = false
		
		//console.log("showWelcome:"+this.props.showWelcome)
	
	}
	
	enableScreenSaver(){
		if (this.mounted)
			this.setState({screensaver:true})
	}
	resetScreenSaverTimer(){
		if (this.mounted)
			this.setState({screensaver:false})
		if (this.screensaverTimer)
			clearInterval(this.screensaverTimer)
		if (!local)
		{
			console.log("roles:"+JSON.stringify(this.props.roles))
			if (this.props.roles.length ===1 &&  this.props.roles[0] === "consument")
			{
				console.log("Consument only")
				this.screensaverTimer = setTimeout( this.enableScreenSaver, 30000);
			}
			else
			{
				console.log("more than Consument only")
			}
		}
	}

	componentDidMount(){
		this.mounted = true
		window.addEventListener('resize', ()=>{ this.handleResize()})
		window.addEventListener('click', ()=>{ this.resetScreenSaverTimer()})
		this.resetScreenSaverTimer()
		this.handleResize()
		api_categories()
		.then( () => api_articles())
	}
	handleResize(){
		const {  innerHeight: height } = window
		if (this.mounted)
			this.setState({windowHeight:height})
	}
	componentWillUnmount(){
		this.mounted = false
		window.removeEventListener('resize', this.handleResize)
		window.removeEventListener('click', this.resetScreenSaverTimer)
		
	}
	
	format_price(price)
	{
		price = price.toString()
		if (price.includes('.')){
			price = price +'00'
			let pos = price.indexOf('.')
			price = price.substring(0,pos+3)
			price = price.replace('.', ',')
		}
		return price
	}
	
	render(){
		return 	<Container id="ctrlcntr" style={{height:'100%'}} fluid className="px-0 py-0" >
					<Stack id="stackall" direction="vertical" gap={1} className="mx-auto px-0 py-0 flex-column" style={{maxWidth:'600px', height:'100%'}}>
						<Menu askOber={this.oberCallRef.askOber}/>
						<OberCall childRef={ref => (this.oberCallRef = ref)}/>
						{!this.state.screensaver && 
							<Stack className="flex-column-reverse py-0" direction="vertical" gap={1} style={{borderRadius:'5px'}}>
								{this.props.menu==='bestellen' && <>{this.render_bestellen_footer()}</>}
								<div className="AppContent flex-grow-1" >
									{this.props.menu==='bestellen' && this.render_bestellen_body()}
									{/*this.props.menu==='transfercredits' && this.render_transfercredits()*/}
									{/*this.props.menu==='sharecredits' && this.render_sharecredits()*/}
									{this.props.menu==='saldo' && this.render_share()}
									{this.props.menu==='bestellingen' && this.render_orderhistory()}
								</div>
							</Stack>
						}
						{this.state.screensaver && <><h4 style={{color:'yellow', backgroundColor:'red'}}>Raak aan om verder te gaan...</h4><div  className="m-auto bg-light " style={{ justifyContent:'center'}}><ScreenSaver/></div></>}
					</Stack>
					<Modal show={this.state.share!==null} onHide={()=>{this.setState({share:null})}} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>Scan de code op een ander toestel</Modal.Title>
						</Modal.Header>
						<Modal.Body className="mx-auto p-5" >
						{this.state.share!==null && <QRCode className="mx-auto"
								bgColor="#FFFFFF"
								fgColor="#000000"
								level="Q"
								style={{ width: '100%' }}
								value={this.state.share}
						/>}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="success" onClick={()=>{this.setState({share:null}); if (this.refCredSel) this.refCredSel.reset()}}>Ok</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.showWelcome} onHide={()=>{this.setState({showWelcome:false})}} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>Welkom...</Modal.Title>
						</Modal.Header>
						<Modal.Body className="mx-auto p-5" >
						<p>Welkom op het eetfestijn van TTC De Pinte. Deze editie heeft een verbeterde <Image src="payconiq256.png"  style={{height:40, color:"red"}}/> integratie om online af te rekenen. Geen <Image src="payconiq256.png"  style={{height:40, color:"red"}} /> op je toestel? Geen probleem, je kan net zoals vorige jaren je saldo voor het eerste gebruik opladen. Gebruik hiervoor de <Image src="raise.png"  style={{width:30, color:'red'}} onClick={this.oberCallRef.askOber}/> - knop, hiermee komt een ober jouw richting uit om jou verder te helpen.</p>
						Smakelijk & Gezondheid!
						</Modal.Body>
						<Modal.Footer>
							<Button variant="success" onClick={()=>{this.setState({showWelcome:false}); api_show_welcome(false) }}>Begrepen</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.transactions} onHide={()=>{this.setState({transactions:undefined})}} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>Saldo Historiek</Modal.Title>
						</Modal.Header>
						<Modal.Body className=" p-1" >
						<Container id="ctrlcntr2" style={{height:'100%'}} fluid className="px-0 py-0" >
							<ListGroup style={{width:'100%'}}>
								{
									this.state.transactions && this.state.transactions.map( transaction => 
									{
										return <ListGroup.Item style={{width:'100%'}} key={transaction.pay_date}> 
										<Stack direction="horizontal" gap={3} className="mx-auto px-1 py-1" >
											<div style={{textAlign:'left', width:'100%'}}>{(new Date(transaction.pay_date)).toLocaleString()}</div>
											<div style={{textAlign:'right'}}>{this.format_price(transaction.amount)}€</div>
										</Stack>
										</ListGroup.Item>
										
											
									})
								}
							</ListGroup>
						</Container>
						</Modal.Body>
						<Modal.Footer>
						<Container id="ctrlcntr2" style={{height:'100%'}} fluid className="px-0 py-0" >
							<Stack direction="horizontal" gap={3} className="mx-auto px-1 py-0" >
								<div style={{textAlign:'left', width:'100%'}}>
									<b>Huidig Saldo: {this.format_price(this.props.credits)}€</b>
								</div>
								<div style={{textAlign:'right'}}>
									<Button variant="success" onClick={()=>{this.setState({transactions:undefined}) }}>OK</Button>
								</div>
							</Stack>
						</Container>
						</Modal.Footer>
					</Modal>
				</Container>
		
	}

	render_bestellen_body() {	
		return 	<Stack className="flex-column p-0 m-0" direction="vertical" gap={0} style={{borderRadius:'5px', height:this.state.windowHeight-8-121, overflow:'auto'}}>
					<div><Notifications/></div>
					<MenuKaart className="flex-grow-1"/>
					<div className="p-1"><MenuKaart permanent='1' /></div>
				</Stack>	
	}
	render_bestellen_footer() {	
		return 	<OrderSummary/>
				
	}
	render_share(){
		return <div style={{height:this.state.windowHeight-8-84, overflow:'auto'}}>
				<Container fluid className="py-1" >
					{this.render_saldo()}
					<hr/>
					{this.render_transfercredits()}
					<hr/>
					{this.render_sharecredits()}
				</Container>
			</div>
	}
	render_saldo() {
		return 	<Stack direction="horizontal" gap={3} className="mx-auto px-1 py-1" ><b>Je huidig saldo bedraagt {this.format_price(this.props.credits)}€</b>
					<Button onClick={this.showTransactions} >Historiek</Button>
					{this.state.error}
				</Stack>
	}
	render_transfercredits(){
			return 	<Stack direction="vertical" gap={3} className="mx-auto px-1 py-1" >
						Je kan een deel van je saldo naar een ander toestel overzetten. Selecteer het bedrag en genereer een QR-code en scan deze QR-code dan in op het andere toestel.
						<br/>
						<CreditsSelector childRef={ref =>(this.refCredSel=ref)} minimumAmount={0} maximumAmount={this.props.credits} onChange={(amount)=>this.setState({amount})}/>
						<Button onClick={()=>this.generateShare(get_server_url()+"?transfer=", this.state.amount)} disabled={this.state.amount===0}>Genereer QR code voor {this.format_price(this.state.amount)}€</Button>
						{this.state.error}
					</Stack>
		
	}
	render_sharecredits() {	
			return 	<Stack direction="vertical" gap={3} className="mx-auto px-1 py-1" >
						Je kan je account en bijhorend saldo delen met een ander toestel. Genereer een QR code met onderstaande knop en scan deze in op het andere toestel.
						<Button onClick={()=>this.generateShare(get_server_url()+"?share=", undefined)}>Genereer QR code</Button>
						{this.state.error}
					</Stack>
	}
	render_orderhistory() {	
		return 		<div style={{height:this.state.windowHeight-8-84, overflow:'auto'}}><OrderHistory orderType="consument" ></OrderHistory></div>
					
				
	}
	generateShare(url, amount) {
		api_create_transfer(this.props.identifier, amount)
		.then( response => {
			if (response.result){
				if (response.data && response.data.transfer_identifier) {
					this.setState({share:url+response.data.transfer_identifier, error:undefined})
				} else {
					this.setState({error:"unknown error"})
				}
			} else if (response.error){
				if (response.data && response.data.error) {
					this.setState({error:response.data.error.msg})
				} else {
					this.setState({error:"minor unknown error"})
				}
			} else {
				this.setState({error:"major unknown error"})
			}
		})
		.catch( error => this.setState({error}))
	}
	
	showTransactions() {
		api_get_payments(this.props.identifier)
		.then (response => {
			console.log("transactions get:"+JSON.stringify(response))
			if (response.result){
				if (response.data && response.data.transactions ) {
					this.setState({transactions:response.data.transactions, error:undefined})
				} else {
					this.setState({error:"unknown error"})
				}
			} else if (response.error){
				if (response.data && response.data.error) {
					this.setState({error:response.data.error.msg})
				} else {
					this.setState({error:"minor unknown error"})
				}
			} else {
				this.setState({error:"major unknown error"})
			}
		})
		.catch( error => this.setState({error}))
	}
}

function mapStateToProps(state) {
	return {
		identifier:state.app.identifier,
		num_orders:state.app.orders.length, 
		credits:state.app.credits, 
		menu:state.app.menu, 
		showWelcome:(state.user.showWelcome!==false),
		roles:state.app.roles
		}
}

export default connect(mapStateToProps)(Consumer); 