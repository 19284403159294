import './App.css';
import React from 'react'
import { connect } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import ReactQueryParams from 'react-query-params'
import Main from './main'
import Register from './register'
import {api_login} from './api'
import {CLEAR_IDENTIFIER, DEL_IDENTIFIER, LOGIN_NOK, LOGIN_RETRY, SET_LOCATION, SET_TRANSFERCREDITS, SET_SHAREACCOUNT} from './actions'

class App extends ReactQueryParams {
	
	constructor(props){
		super(props)
		this.state={msg:"", windowHeight:0}
		this.login = this.login.bind(this)
		this.handleResize = this.handleResize.bind(this)
		
	}
	handleResize(){
		const {  innerHeight: height } = window
		this.setState({windowHeight:height})
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize)
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.check_for_login()
	}
	
	check_for_login() {
		if (this.props.loggedIn === false && this.props.loggedOut===false) {
			if (this.props.loggedInFailed === false) {
				if (this.props.identifier !== null && this.props.identifier.length>0 && (this.props.share===undefined || this.props.share===null)){
					//console.log("logging in")
					this.login()
				}
			}
		}
	}
	
	login(){
		//console.log("logging in(login)")
		api_login(this.props.identifier)
		.then( result => {
			if (result.error)
			{
				this.setState({ msg:result.error})
			}
			else if ( result.data){
				if (result.data.error) {
					this.setState({msg:result.data.error.msg})
				} else {
					//all ok?
				}
			}
		})
		.catch (err => {
			console.log("something terrible went wrong")
			console.log(err)
			this.setState({msg:err.toString()})
			this.props.login_failed()
		})
	}
	
	componentDidMount(){
		window.addEventListener('resize', ()=>{ this.handleResize()})
		this.handleResize()
		//console.log("queryparams="+JSON.stringify(this.queryParams))
		if (this.queryParams.loc)
			this.props.setLocation(this.queryParams.loc)
		this.props.setTransferCredits(this.queryParams.transfer)
		this.props.setShareAccount(this.queryParams.share)
		this.check_for_login()
		
	}

	render() {
		//console.log("this.props.share:"+JSON.stringify(this.props.share))
		let ctrl = <>{"Momentje..."}</>
		
		if (this.props.loggedIn === false) {
			if (this.props.loggedInFailed === false) {
				if (!this.props.loggedOut && this.props.identifier !== null && this.props.identifier.length>0 && (this.props.share===undefined || this.props.share===null)){
					ctrl = <>{"Aan het inloggen..."}</>
				}
				else{
					ctrl = <Register username={this.queryParams.name}/>
				}
			}
			else {
				ctrl = <Stack gap={1}>
						{"Fout bij het inloggen."}
						<br/>
						{this.state.msg}<br/>
						<Button onClick={this.props.login_retry}>Opnieuw inloggen</Button>
						<Button onClick={this.props.register}>Opnieuw registreren</Button>
						<Button onClick={()=>{this.props.removeIdentifier(this.props.identifier); this.props.register()}}>Account verwijderen</Button>
						
					</Stack>
			}
		}
		else {
			ctrl = <Main/>
			 
		}
		let className="p-1 m-0 flex-column App "
		//if (!this.props.online) className +=" Offline"
		return (
				<Container id="maxvenster" className={className} fluid style={{height:this.state.windowHeight}}>
					{/*<div style={{height:'100%', overflow:'auto'}}>*/}
						<Row id="ctrlrow" className="mx-auto justify-content-center flex-grow-1 p-0" style={{height:'100%', overflow:'auto'}}>{ctrl}</Row>
							{/*</div>*/}
				</Container>
			)
	}
}
function mapDispatchToProps(dispatch) {
	return {
		register: () => {console.log("clear");dispatch({ type: CLEAR_IDENTIFIER  })},
		login_failed: () => {console.log("clear");dispatch({ type: LOGIN_NOK  })},
		login_retry: () => {console.log("clear");dispatch({ type: LOGIN_RETRY  })},
		setLocation: (location) => dispatch({type:SET_LOCATION, location}),
		setTransferCredits: (transfer) => dispatch({type:SET_TRANSFERCREDITS, transfer}),
		setShareAccount: (share) => dispatch({type:SET_SHAREACCOUNT, share}),
		removeIdentifier: (identifier) => dispatch({type:DEL_IDENTIFIER, identifier})
		
	}
}
function mapStateToProps(state) {
	return {identifier:state.app.identifier, loggedIn:state.app.loggedIn, loggedOut:state.app.loggedIn, loggedInFailed:state.app.loggedInFailed, location: state.user.location,share:state.app.share, online:state.connection.online}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(App);  




